import { createSlice, current } from "@reduxjs/toolkit";
import {
  saveDesignAutomations,
  fetchDesignAutomationsHistory,
  clearDesignAutomationHistory,
  toggleCheckBoxDesignAutomationsHistory,
  checkAllCheckBoxDesignAutomationsHistory,
} from "./designAutomationsActions";
// import type { RootState } from '../../features/storeConfig/store'
import type { PayloadAction } from '@reduxjs/toolkit'

type InitialStateType = {
  objSaveDesignAutomationsFormSuccess?: any;
  objDesignAutomations?: any;
  loading?: boolean;
}
const initialState: InitialStateType = {
  objDesignAutomations: {
    objDesignAutomations: [],
    total_design_automations: 0,
    error: false
  }
};

const designAutomationSlice: any = createSlice({
  name: "designAutomation",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      // objSaveDesignAutomationsFormSuccess
      .addCase(saveDesignAutomations.pending, (state) => {
        state.loading = true;
        state.objSaveDesignAutomationsFormSuccess = null;
      })
      .addCase(saveDesignAutomations.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSaveDesignAutomationsFormSuccess = action.payload;
      })
      .addCase(saveDesignAutomations.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSaveDesignAutomationsFormSuccess = action.payload;
      })

      // objDesignAutomations
      // .addCase(fetchDesignAutomationsHistory.pending, (state) => {
      //   state.loading = true;
      //   state.objDesignAutomations = {};
      //   // state.objDesignAutomations = null;
      // })
      .addCase(fetchDesignAutomationsHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // console.log('action.payload in fetchDesignAutomationsHistory.fulfilled');
        // console.log('action.payload in fetchDesignAutomationsHistory.fulfilled');
        // console.log(action.payload);
        // console.log('state in fetchDesignAutomationsHistory.fulfilled');
        // console.log('state in fetchDesignAutomationsHistory.fulfilled');
        // console.log(state);
        // console.log("current(state)");
        // console.log("current(state)");
        // console.log("current(state)");
        // console.log(current(state));
        // console.log("current(state.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations)");
        // console.log(current(state.objDesignAutomations));
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log(current(state.objDesignAutomations.objDesignAutomations));
        state.objDesignAutomations.objDesignAutomations = [...state.objDesignAutomations.objDesignAutomations, ...action.payload.objDesignAutomations]
        state.objDesignAutomations.error = action.payload.error;
        state.objDesignAutomations.msg = action.payload.msg;
        state.objDesignAutomations.total_design_automations = action.payload.total_design_automations;
        // console.log("current(state.objDesignAutomations.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations.objDesignAutomations)");
        // console.log(current(state.objDesignAutomations.objDesignAutomations.objDesignAutomations));

        // console.log('state.objDesignAutomations in fetchDesignAutomationsHistory.fulfilled');
        // console.log('state.objDesignAutomations in fetchDesignAutomationsHistory.fulfilled');
        // console.log(state.objDesignAutomations);
        // if(state.objDesignAutomations  === undefined){
        // // if(state.objDesignAutomations.objDesignAutomations.length  === 0){
        //   return {...state, objDesignAutomations:action.payload}
        // }else{
        //  return {
        //   ...state,
        //     // ...state.objDesignAutomations,
        //     objDesignAutomations: {
        //       ...state.objDesignAutomations,
        //       error: action.payload.error,
        //       msg: action.payload.msg,
        //       total_design_automations: action.payload.total_design_automations,
        //       // objDesignAutomations:action.payload.objDesignAutomations,
        //       objDesignAutomations: [...state.objDesignAutomations.objDesignAutomations, ...action.payload.objDesignAutomations]
        //     }

        //   }
        // }
      })
      .addCase(fetchDesignAutomationsHistory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objDesignAutomations = action.payload;
      })

      // clearDesignAutomationHistory
      .addCase(clearDesignAutomationHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objDesignAutomations = action.payload;
      })

      // toggleCheckBoxDesignAutomationsHistory
      .addCase(toggleCheckBoxDesignAutomationsHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // console.log('action.payload in toggleCheckBoxDesignAutomationsHistory.fulfilled');
        // console.log('action.payload in toggleCheckBoxDesignAutomationsHistory.fulfilled');
        // console.log(action.payload);
        // console.log('state in toggleCheckBoxDesignAutomationsHistory.fulfilled');
        // console.log('state in toggleCheckBoxDesignAutomationsHistory.fulfilled');
        // console.log(state);
        // console.log("current(state)");
        // console.log("current(state)");
        // console.log("current(state)");
        // console.log(current(state));
        // console.log("current(state.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations)");
        // console.log(current(state.objDesignAutomations));
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log(current(state.objDesignAutomations.objDesignAutomations));
        const objAutomationIds = action.payload;
        let objMainSelectedAutomation = state.objDesignAutomations.objDesignAutomations.find(
          (item: any) => item._id === objAutomationIds.automation_design_id
        );
        console.log("objMainSelectedAutomation");
        console.log("objMainSelectedAutomation");
        console.log("objMainSelectedAutomation");
        console.log(objMainSelectedAutomation);
        if (objMainSelectedAutomation !== undefined) {
          let objSelectedAutomation = objMainSelectedAutomation.objAutomationsList.find(
            (item: any) => item.id === objAutomationIds.id
          );
          if (objSelectedAutomation !== undefined) {
            objSelectedAutomation.is_checked = !objSelectedAutomation.is_checked;
          }
        }
      })
      .addCase(toggleCheckBoxDesignAutomationsHistory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objDesignAutomations = action.payload;
      })

      // checkAllCheckBoxDesignAutomationsHistory
      .addCase(checkAllCheckBoxDesignAutomationsHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        console.log('action.payload in checkAllCheckBoxDesignAutomationsHistory.fulfilled');
        console.log('action.payload in checkAllCheckBoxDesignAutomationsHistory.fulfilled');
        console.log(action.payload);
        // console.log('state in checkAllCheckBoxDesignAutomationsHistory.fulfilled');
        // console.log('state in checkAllCheckBoxDesignAutomationsHistory.fulfilled');
        // console.log(state);
        // console.log("current(state)");
        // console.log("current(state)");
        // console.log("current(state)");
        // console.log(current(state));
        // console.log("current(state.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations)");
        // console.log(current(state.objDesignAutomations));
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log("current(state.objDesignAutomations.objDesignAutomations)");
        // console.log(current(state.objDesignAutomations.objDesignAutomations));
        for (let index = 0; index < state.objDesignAutomations.objDesignAutomations.length; index++) {
          const element = state.objDesignAutomations.objDesignAutomations[index];
          
          for (let indexAutomationList = 0; indexAutomationList < state.objDesignAutomations.objDesignAutomations[index].objAutomationsList.length; indexAutomationList++) {
              const elementSub = state.objDesignAutomations.objDesignAutomations[index].objAutomationsList[indexAutomationList];
              state.objDesignAutomations.objDesignAutomations[index].objAutomationsList[indexAutomationList].is_checked = true;    
          }
        }

        // const nextStateObjDesignAutomations = state.objDesignAutomations.objDesignAutomations.map((item: any) => {
        //   // this is our relevant item, return a new copy of it with modified fields
        //   const nextItem: any = item.objAutomationsList.map((subItem: any) => {
        //     // this is our relevant item, return a new copy of it with modified fields
        //     const is_checked = true;
        //     console.log('is_checked')
        //     console.log('is_checked')
        //     console.log(is_checked)
        //     return {
        //       ...subItem,
        //       is_checked,
        //     }
        //   })
        //   return {
        //     ...nextItem,
        //   }
        // });
        // console.log("nextStateObjDesignAutomations");
        // console.log("nextStateObjDesignAutomations");
        // console.log("nextStateObjDesignAutomations");
        // console.log(nextStateObjDesignAutomations);



      })
      .addCase(checkAllCheckBoxDesignAutomationsHistory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objDesignAutomations = action.payload;
      })

  },
});

export default designAutomationSlice.reducer;
