import React, { useState, useContext, ReactNode, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import Popovers from '../../components/bootstrap/Popovers';
import UserImage3 from '../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../assets/img/wanna/wanna3.webp';

const User = () => {
	const { objUser } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);
	// console.log('objUser in User sidebar')
	// console.log('objUser in User sidebar')
	// console.log('objUser in User sidebar')
	// console.log(objUser)
	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
					{
						objUser.isAuth ?
						<Fragment>
							<div className='user-avatar'>
								<img
									// srcSet={objUser?.srcSet}
									// src={objUser?.src}
									srcSet={UserImage3Webp}
									src={UserImage3}
									alt={objUser.full_name}
									width={128}
									height={128}
								/>
							</div>
							<div className='user-info'>
								<div className='user-name'>
									{/* <Popovers title='User.tsx' desc={<code>src/layout/User/User.tsx</code>}>
										{`${objUser?.name} ${objUser?.surname}`}
									</Popovers> */}
									<code className='ps-2'>Hello, {objUser.full_name}</code>
								</div>
								<div className='user-sub-title'>
									{/* <Popovers title='User.tsx' desc={<code>src/layout/User/User.tsx</code>}>
										User
									</Popovers> */}
									{/* <code className='ps-2'>{objUser.username}</code> */}
								</div>
							</div>
						</Fragment>
						: null
					}
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/account-settings`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div> */}
					</div>
				</nav>
				<NavigationLine />
				{/* <nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								// if (setUser) {
								// 	setUser('');
								// }
								// navigate(`../${demoPagesMenu.login.path}`);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav> */}
			</Collapse>
		</>
	);
};

export default User;
