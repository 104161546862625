import React,{useState} from 'react';
import {  Modal, ModalHeader, ModalBody, ModalFooter, Card,
    CardHeader,
    CardBody,
    CardTitle,
} from 'reactstrap';

import ScheduleAutomationModalBody from './ScheduleAutomationModalBody';

const ScheduleAutomationModal = (props: any) => {
    const {
        isShowScheduleAutomationModal,setIsShowScheduleAutomationModal,
        objDesignAutomations,
    } = props;
    const toggle = () => {
        setIsShowScheduleAutomationModal(!isShowScheduleAutomationModal);
    };
    

    return (
        <div>
            <Modal backdrop={false} size="xl" className='ScheduleAutomationModal' isOpen={isShowScheduleAutomationModal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Schedule Automation</ModalHeader>
                <ModalBody className='px-4'>
                    <div className='row g-4'>

                    </div>
                    {/* <Card className='rounded-1 mb-0 ScheduleAutomationModalSection' > */}
                        {/* <CardHeader>
                            <CardTitle>Schedule Automation</CardTitle>
                        </CardHeader> */}
                        {/* <CardBody> */}
                            <div className='row g-3 rounded-1 mb-0 ScheduleAutomationModalSection' > 
                                <ScheduleAutomationModalBody 
                                    toggle={toggle}
                                    objDesignAutomations={objDesignAutomations}
                                />
                            </div>

                        {/* </CardBody> */}
                    {/* </Card> */}
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>Exit</Button>
                </ModalFooter> */}
            </Modal>
        </div>
    );
}

export default ScheduleAutomationModal;