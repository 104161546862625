import React, { FC, Fragment, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Label, Form, FormGroup, Input } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Select from "react-select"
import makeAnimated from "react-select/animated";
import { useAppDispatch } from '../../../../../features/storeConfig/store';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';
import SourceDynamicTreeSection from './SourceDynamicTreeSection';


const animatedComponents = makeAnimated();
interface ISourceAutomationSectionProps {
    formattedWorkSpacesArr: any;
    setFormattedWorkSpacesArr: any;
    objSelectedSourceWorkSpace: any;
    setObjSelectedSourceWorkSpace: any;
    formattedSourceFoldersArr: any;
    setFormattedSourceFoldersArr: any;
    objSelectedSourceFolder: any;
    setObjSelectedSourceFolder: any;
    formattedSourceSheetsArr: any;
    setFormattedSourceSheetsArr: any;
    objSelectedSourceSheet: any;
    setObjSelectedSourceSheet: any;
    formattedSourceColumnsArr: any;
    setFormattedSourceColumnsArr: any;
    objSelectedSourceColumn: any;
    setObjSelectedSourceColumn: any;
    fetchWorkSpaceDataByWorkSpaceIdHandler: any;
    fetchWorkSpaceFoldersByWorkSpaceIdHandler: any;
    fetchWorkSpaceFolderSheetsByFolderIdHandler: any;
    fetchWorkSpaceSheetColumnsBySheetIdHandler: any;
    isLoadingAllWorkSpaces: boolean;
    isLoadingSourceWorkSpaceFoldersByWorkSpaceId: boolean;
    isLoadingSourceWorkSpaceFolderSheetsByFolderId: boolean;
    isLoadingSourceWorkSpaceFolderSheetColumnsBySheetId: boolean;
    isLoadingSourceWorkSpaceByWorkSpaceId: boolean;
    objFormErrors:any
}
const SourceAutomationSection: FC<ISourceAutomationSectionProps> = (props) => {
    const { formattedWorkSpacesArr,setFormattedWorkSpacesArr,objSelectedSourceWorkSpace,
        setObjSelectedSourceWorkSpace,formattedSourceFoldersArr,setFormattedSourceFoldersArr,
        objSelectedSourceFolder,setObjSelectedSourceFolder,formattedSourceSheetsArr,
        setFormattedSourceSheetsArr,objSelectedSourceSheet,setObjSelectedSourceSheet,
        formattedSourceColumnsArr,setFormattedSourceColumnsArr,objSelectedSourceColumn,
        setObjSelectedSourceColumn,fetchWorkSpaceFoldersByWorkSpaceIdHandler,
        fetchWorkSpaceDataByWorkSpaceIdHandler,
        fetchWorkSpaceFolderSheetsByFolderIdHandler,
        fetchWorkSpaceSheetColumnsBySheetIdHandler,
        isLoadingAllWorkSpaces,isLoadingSourceWorkSpaceFoldersByWorkSpaceId,isLoadingSourceWorkSpaceFolderSheetsByFolderId,isLoadingSourceWorkSpaceFolderSheetColumnsBySheetId,
        isLoadingSourceWorkSpaceByWorkSpaceId,
        objFormErrors
    } = props;

    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const handleOnChangeSourceWorkSpace = (selectedOptions: any) => {
    //     console.log('selectedOptions in handleOnChangeSourceWorkSpace');
    //     console.log('selectedOptions in handleOnChangeSourceWorkSpace');
    //     console.log(selectedOptions);
    //     setObjSelectedSourceWorkSpace(selectedOptions);
    //     setFormattedSourceFoldersArr([]);
    //     setFormattedSourceSheetsArr([]);
    //     setFormattedSourceColumnsArr([]);
    //     setObjSelectedSourceFolder({});
    //     setObjSelectedSourceSheet({});
    //     setObjSelectedSourceColumn({});
    //     fetchWorkSpaceFoldersByWorkSpaceIdHandler(selectedOptions.id,'source');
    // };

    // const handleOnChangeSourceFolder = (selectedOptions: any) => {
    //     console.log('selectedOptions in handleOnChangeSourceFolder');
    //     console.log('selectedOptions in handleOnChangeSourceFolder');
    //     console.log(selectedOptions);
    //     setObjSelectedSourceFolder(selectedOptions);
    //     setFormattedSourceSheetsArr([]);
    //     setFormattedSourceColumnsArr([]);
    //     setObjSelectedSourceSheet({});
    //     setObjSelectedSourceColumn({});
    //     fetchWorkSpaceFolderSheetsByFolderIdHandler(selectedOptions.id,'source')
    // };

    const handleOnChangeSourceSheet = (selectedOptions: any) => {
        console.log('selectedOptions in handleOnChangeSourceSheet');
        console.log('selectedOptions in handleOnChangeSourceSheet');
        console.log(selectedOptions);
        setObjSelectedSourceSheet(selectedOptions);
        setFormattedSourceColumnsArr([]);
        setObjSelectedSourceColumn({});
        fetchWorkSpaceSheetColumnsBySheetIdHandler(selectedOptions.id,'source');
    };

    const handleOnChangeSourceColumn = (selectedOptions: any) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedSourceColumn(selectedOptions);
        
    };

    return (
        <Col md="6">
            <Card>
                <CardHeader>
                    <CardLabel icon='Source' iconColor='warning'>
                        <CardTitle tag='div' className='h5'>
                            Source
                        </CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody>
                    {
                        isLoadingAllWorkSpaces === false ?
                        <Fragment>
                            <SourceDynamicTreeSection 
                                formattedWorkSpacesArr={formattedWorkSpacesArr}
                                setFormattedWorkSpacesArr={setFormattedWorkSpacesArr}
                                fetchWorkSpaceFoldersByWorkSpaceIdHandler={fetchWorkSpaceFoldersByWorkSpaceIdHandler}
                                fetchWorkSpaceFolderSheetsByFolderIdHandler={fetchWorkSpaceFolderSheetsByFolderIdHandler}
                                handleOnChangeSourceSheet={handleOnChangeSourceSheet}
                                setObjSelectedSourceWorkSpace={setObjSelectedSourceWorkSpace}
                                setObjSelectedSourceFolder={setObjSelectedSourceFolder}
                            />
                            <Form id={"SourceAutomationForm"}>
                                {/* <Row className='g-4'>
                                    <Col md="6">
                                        <FormGroup className={`${objFormErrors.objSelectedSourceWorkSpace  && "text-danger"}`}>
                                            <h6 className="text-bold-100">Workspace</h6>
                                            <Select
                                                components={animatedComponents}
                                                name="drpSourceWorkSpace"
                                                options={formattedWorkSpacesArr}
                                                className="React"
                                                classNamePrefix="select"
                                                value={objSelectedSourceWorkSpace}
                                                onChange={handleOnChangeSourceWorkSpace}
                                            />
                                            {objFormErrors.objSelectedSourceWorkSpace ? (
                                                <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedSourceWorkSpace}</div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        {
                                            isLoadingSourceWorkSpaceFoldersByWorkSpaceId === false && isLoadingSourceWorkSpaceByWorkSpaceId === false ?
                                            <FormGroup className={``}>
                                                <h6 className="text-bold-100">Folder</h6>
                                                <Select
                                                    components={animatedComponents}
                                                    name="drpSourceFolder"
                                                    options={formattedSourceFoldersArr}
                                                    className="React"
                                                    classNamePrefix="select"
                                                    value={objSelectedSourceFolder}
                                                    onChange={handleOnChangeSourceFolder}
                                                />
                                                
                                            </FormGroup>
                                            : <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                                        }
                                    </Col>
                                </Row> */}
                                <Row className='g-4'>
                                    
                                    {/* <Col md="6">
                                        {
                                            isLoadingSourceWorkSpaceFolderSheetsByFolderId === false && isLoadingSourceWorkSpaceByWorkSpaceId === false ?
                                            <FormGroup className={`${objFormErrors.objSelectedSourceSheet  && "text-danger"}`}>
                                                <h6 className="text-bold-100">Sheets</h6>
                                                <Select
                                                    components={animatedComponents}
                                                    name="drpSourceSheet"
                                                    options={formattedSourceSheetsArr}
                                                    className="React"
                                                    classNamePrefix="select"
                                                    value={objSelectedSourceSheet}
                                                    onChange={handleOnChangeSourceSheet}
                                                />
                                                {objFormErrors.objSelectedSourceSheet ? (
                                                    <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedSourceSheet}</div>
                                                ) : null}
                                            </FormGroup>
                                            : <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />

                                        }
                                    </Col> */}
                                    <Col md="12">
                                            {
                                                isLoadingSourceWorkSpaceFolderSheetColumnsBySheetId === false ?
                                                <FormGroup className={`${objFormErrors.objSelectedSourceColumn  && "text-danger"}`}>
                                                    <h6 className="text-bold-100">Column</h6>
                                                    <Select
                                                        components={animatedComponents}
                                                        name="drpSourceColumn"
                                                        options={formattedSourceColumnsArr}
                                                        className="React"
                                                        classNamePrefix="select"
                                                        value={objSelectedSourceColumn}
                                                        onChange={handleOnChangeSourceColumn}
                                                    />
                                                    {objFormErrors.objSelectedSourceColumn ? (
                                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedSourceColumn}</div>
                                                    ) : null}
                                                </FormGroup>
                                                : <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                                            }
                                    </Col>
                                    
                                </Row>
                            </Form>
                        </Fragment>
                        : 
                        <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                    }

                </CardBody>
            </Card>
        </Col>

    );
};


export default SourceAutomationSection;
