import React from 'react';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Popovers from '../../../components/bootstrap/Popovers';
import DashboardYoutubeDemoVideo from './components/DashboardYoutubeDemoVideo/DashboardYoutubeDemoVideo';
import "../../../styles/pages/DashboardPage.scss";
import ConnectToSmartSheetSection from './components/ConnectToSmartSheetSection/ConnectToSmartSheetSection';
const DashboardPage = () => {
	return (
		<PageWrapper 
			title='Dashboard'
			isProtected={false}
			page_auth_type={'dashboard_page'}
			checkAuth={true}
			isRedirect={true}
		>
			{/* <SubHeader>
				<SubHeaderLeft>
					<Popovers
						title='DashboardPage.tsx'
						desc={<code>src/pages/presentation/dashboard/DashboardPage.tsx</code>}>
						SubHeaderLeft
					</Popovers>
					<code>DashboardPage.tsx</code>
					<SubheaderSeparator />
				</SubHeaderLeft>
				<SubHeaderRight>
					<Popovers
						title='DashboardPage.tsx'
						desc={<code>src/pages/presentation/dashboard/DashboardPage.tsx</code>}>
						SubHeaderRight
					</Popovers>
					<code>DashboardPage.tsx</code>
				</SubHeaderRight>
			</SubHeader> */}
			<Page container='fluid'>
				{/* <div className='row'>
					<div className='col-12 mb-3'>
						<Popovers
							title='DashboardPage.tsx'
							desc={<code>src/pages/presentation/dashboard/DashboardPage.tsx</code>}>
							Page
						</Popovers>
						<code className='ps-3'>DashboardPage.tsx</code>
					</div>
				</div> */}
				<div className='row'>

					<div className='col-lg-8'>
							{/* <CommonMyWallet /> */}
							<DashboardYoutubeDemoVideo />
						</div>
						<div className='col-lg-4'>
							<ConnectToSmartSheetSection />
						</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;
