import React, { FC, useCallback, useContext, useState, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../../layout/Page/Page';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
// import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
// import Input from '../../../../../components/bootstrap/forms/Input';
import Button from '../../../../../components/bootstrap/Button';
import Logo from '../../../../../components/Logo';
import useDarkMode from '../../../../../hooks/useDarkMode';
import AuthContext from '../../../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../../../common/data/userDummyData';
import Spinner from '../../../../../components/bootstrap/Spinner';
import Alert from '../../../../../components/bootstrap/Alert';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import LogoPng from "../../../../assets/img/logo.png";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import CommonAlert from '../../../../../common/alerts/CommonAltert';
import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';

interface ILoginFormFormikWrapperProps {
    values?: any;
    errors?: any;
    touched?: any;
    setFieldValue?: any;
    isLoading: boolean,
}
const LoginFormFormikWrapper: FC<ILoginFormFormikWrapperProps> = (props) => {
    const { values, errors, touched, setFieldValue, isLoading } = props;
    return (
        <Fragment>
            <div className='col-12'>

                <FormGroup className={`${errors.email && touched.email && "text-danger"}`}>
                    <Label >Email</Label>
                    <Field
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        className={`form-control ${errors.email &&
                            touched.email &&
                            "is-invalid"}`}
                    />
                    {errors.email && touched.email ? (
                        <div className="invalid-feedback">{errors.email}</div>
                    ) : null}
                </FormGroup>
                <FormGroup className={`${errors.password && touched.password && "text-danger"}`}>
                    <Label >Password</Label>
                    <Field
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        className={`form-control ${errors.password &&
                            touched.password &&
                            "is-invalid"}`}
                    />
                    {errors.password && touched.password ? (
                        <div className="invalid-feedback">{errors.password}</div>
                    ) : null}
                </FormGroup>

            </div>
            <div className='col-12'>
                {/* <CommonAlert color='info' icon='Cancel'>
                    
                </CommonAlert> */}
                <p className="text-info">Forgot password? Please email Cascade@CuratedAutomation.com for password reset assistance.</p>
            </div>
            <div className='col-12'>
                {
                    isLoading === false ?
                    <Button
                        color='info'
                        className='w-100 py-3 btnLogin'
                        type='submit'
                    >
                        Login
                    </Button>
                : 
                <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                
            }
            </div>
            

        </Fragment>



    );
};


export default LoginFormFormikWrapper;
