// export const buildFolderNode = (item:any) => {
//     const node : any = {
//         id: item.id,
//         name: item.name,
//         permalink: item.permalink,
//         value: item.id, label: item.name, children:[]
//     };
    
//     if (item.folders) {
//         node.children = item.folders.map(buildFolderNode);
//         // node.folders = item.folders.map(buildFolderNode);

//     }

//     if (item.sheets) {
//         node.sheets = item.sheets.map((sheet:any) => ({
//             id: sheet.id,
//             name: sheet.name,
//             permalink: sheet.permalink,
//             value: sheet.id, label: sheet.name,
//         }));
//         for (let index = 0; index < node.sheets.length; index++) {
//             const element = node.sheets[index];
//             node.children.push(element);
//         }
//     }

//     return node;
// }
export const buildFolderTree = (data:any,_objWorkSpace:any) => {
    const folderTree = [];
    const buildFolderNode = (item:any) => {
        const node : any = {
            id: item.id,
            name: item.name,
            permalink: item.permalink,
            value: item.id, label: item.name, children:[],
            workspace_name: _objWorkSpace.name,
            worspace_id: _objWorkSpace.id,
        };
        
        if (item.folders) {
            node.children = item.folders.map(buildFolderNode);
            // node.folders = item.folders.map(buildFolderNode);
    
        }
    
        if (item.sheets) {
            node.sheets = item.sheets.map((sheet:any) => ({
                id: sheet.id,
                name: sheet.name,
                permalink: sheet.permalink,
                value: sheet.id, label: sheet.name,
                workspace_name: _objWorkSpace.name,
                worspace_id: _objWorkSpace.id,
            }));
            for (let index = 0; index < node.sheets.length; index++) {
                const element = node.sheets[index];
                node.children.push(element);
            }
        }
    
        return node;
    }
    for (const item of data) {
        folderTree.push(buildFolderNode(item));
    }

    return folderTree;
}