import React, { FC, Fragment } from "react"
import AutomationsListTr from './AutomationsListTr';
interface IAutomationsListItemBlockProps {
    list: any;
    btnOnClickRemoveAutomation: any;
    darkModeStatus: boolean;
}
const AutomationsListItemBlock: FC<IAutomationsListItemBlockProps> = (props) => {
    const { list, darkModeStatus
    } = props;
    const renderAutomationListItem = () => (
        props.list ?
            props.list.map((objAutomation:any) => (
                <AutomationsListTr
                    key={objAutomation._id}
                    darkModeStatus={darkModeStatus}
                    btnOnClickRemoveAutomation={props.btnOnClickRemoveAutomation}
                    {...objAutomation}
                />
            ))
            : null
    )
    return (
        <Fragment>
            {renderAutomationListItem()}
        </Fragment>
    )
}
export default AutomationsListItemBlock
