import React, { FC, Fragment } from "react";
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import Button from "../../../../../components/bootstrap/Button";
interface IAutomationManagerSearchFormProps {
    btnOnClickSearch: any;
    btnOnClickReset: any;
    txtAutomationJobName: any;
    setTxtAutomationJobName: any;
}
const AutomationManagerSearchForm: FC<IAutomationManagerSearchFormProps> = (props) => {
    const { btnOnClickSearch, txtAutomationJobName, setTxtAutomationJobName, 
        btnOnClickReset
    } = props;
    return (
        <Fragment>
            <Row className='g-4'>
                <Col md="4">
                    <FormGroup className={`form-group`}>
                        <Label >Automation Job Name</Label>
                        <Input
                            type="text"
                            name="txtAutomationJobName"
                            id="txtAutomationJobName"
                            placeholder="Automation Job Name"
                            className={`form-control `}
                            onChange={(e) => setTxtAutomationJobName(e.target.value)}
                            value={txtAutomationJobName}
                        />
                    </FormGroup>
                </Col>
                <Col md="8">
                    <Button
                        color='warning'
                        icon='Search'
                        isLight
                        className='mt-25'
                        onClick={() => btnOnClickReset()}
                    >
                        Reset
                    </Button>
                    <Button
                        color='info'
                        icon='Search'
                        isLight
                        className='mt-25 ml-1 ml-5'
                        onClick={() => btnOnClickSearch()}
                    >
                        Search
                    </Button>
                </Col>
            </Row>
        </Fragment>
    )
}
export default AutomationManagerSearchForm
