export function getUniqueName(){
    // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
    let timestamp = new Date().getUTCMilliseconds();
    return timestamp + Math.random().toString(36).substr(2, 9);
}
export function getUniqueNameWithTimeStamp(){
    return  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
export function getUniqueId() {
    return `${Date.now()}-${getUniqueName()}-${getUniqueNameWithTimeStamp()}`;
}