// import React, { useState, useRef } from 'react';
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import { Col } from 'reactstrap';
// import { useDispatch, useSelector } from "react-redux";
// import { unwrapResult } from "@reduxjs/toolkit";
// import Select from "react-select"
// import makeAnimated from "react-select/animated";
// import { useAppDispatch } from '../../../../../features/storeConfig/store';
// import Card, {
//     CardActions,
//     CardBody,
//     CardFooter,
//     CardFooterLeft,
//     CardFooterRight,
//     CardHeader,
//     CardLabel,
//     CardSubTitle,
//     CardTitle,
// } from '../../../../../components/bootstrap/Card';
// import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';
// import "./stylesDynamicTree.css";
// const animatedComponents = makeAnimated();

// import 'react-folder-tree/dist/style.css';
import Button from '../../../../../components/bootstrap/Button';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
interface IDestinationDynamicTreeSectionProps {
    formattedWorkSpacesArr: any;
    setFormattedWorkSpacesArr: any;
    fetchWorkSpaceFoldersByWorkSpaceIdHandler: any;
    fetchWorkSpaceFolderSheetsByFolderIdHandler: any;
    setObjSelectedDestinationWorkSpace: any;
    setObjSelectedDestinationFolder: any;
    setObjSelectedDestinationSheet: any;
    setObjSelectedDestinationColumn: any;
    handleOnChangeDestinationSheet: any;
}

const DestinationDynamicTreeSection: FC<IDestinationDynamicTreeSectionProps> = (props) => {
    const {
        formattedWorkSpacesArr,
        setFormattedWorkSpacesArr,
        fetchWorkSpaceFoldersByWorkSpaceIdHandler,
        fetchWorkSpaceFolderSheetsByFolderIdHandler,
        setObjSelectedDestinationWorkSpace, setObjSelectedDestinationFolder,
        setObjSelectedDestinationSheet, setObjSelectedDestinationColumn, handleOnChangeDestinationSheet
    } = props;


   
    const [checked, setChecked] = useState<any>([
        // 'persian',
        // 'spqr',
        // 'byzantine',
        // 'holy-roman',
        // 'inca',
    ]);
    const [expanded, setExpanded] = useState([
        // 'favorite-empires',
        // 'classical-era',
        // 'medieval-era',
    ]);


    const onExpand = (value: any,targetNode:any) => {
        console.log("formattedWorkSpacesArr in onExpand")
        console.log("formattedWorkSpacesArr in onExpand")
        console.log(formattedWorkSpacesArr)
        console.log("value in onExpand")
        console.log("value in onExpand")
        console.log(value)
        setExpanded(value);
        console.log("targetNode in onExpand")
        console.log("targetNode in onExpand")
        console.log(targetNode)
        console.log("targetNode.value in onExpand")
        console.log("targetNode.value in onExpand")
        console.log(targetNode.value);
        // if(targetNode.checked && Object.keys(targetNode.parent).length === 0 &&  targetNode.treeDepth === 0 ){
        if(Object.keys(targetNode.parent).length === 0){
            // workspace
            fetchWorkSpaceFoldersByWorkSpaceIdHandler(targetNode.value,"destination");
            setObjSelectedDestinationWorkSpace({
                value: targetNode.value,
                label: targetNode.label,
                name: targetNode.label,
                id: targetNode.value,
            })
        }
        // if(Array.isArray(value)){
        //     // workspace or folder
        //     if(value.length === 1){
        //         // workspace
        //         fetchWorkSpaceFoldersByWorkSpaceIdHandler(value[0],"destination");
        //         setObjSelectedDestinationWorkSpace({
        //             value: targetNode.value,
        //             label: targetNode.label,
        //             name: targetNode.label,
        //             id: targetNode.value,
        //         })
        //     }else if(value.length > 1){
        //         // const workspace_id = value[0];
        //         // const {treeDepth} = targetNode;
        //         // if(targetNode.expanded){
        //         //     fetchWorkSpaceFolderSheetsByFolderIdHandler(targetNode.value,"source",treeDepth,workspace_id);
        //         // }

        //     }
        // }else{
        //     // sheet id
        // }
    };
    
    

    return (
        <Col md="12">
            <CheckboxTree
                checked={checked}
                expanded={expanded}
                nodes={formattedWorkSpacesArr}
                onlyLeafCheckboxes
                onCheck={(_checked:any, node:any) => {
                    // setChecked(_checked)
                    // console.log('_checked')
                    // console.log(_checked)
                    setChecked([node.value])
                    console.log('node in onCheck destination')
                    console.log(node)
                    console.log('node.value in onCheck destination')
                    console.log(node.value)
                    const _objSelectedSheetData = {
                        value: node.value,
                        label: node.label,
                        name: node.label,
                        id: node.value,
                    }
                    handleOnChangeDestinationSheet(_objSelectedSheetData);
                    const objParentNode = node.parent;
                    if(node.treeDepth === 1){
                        // workspace treeDepth 1
                        setObjSelectedDestinationWorkSpace({
                            value: objParentNode.value,
                            label: objParentNode.label,
                            name: objParentNode.label,
                            id: objParentNode.value,
                        })
                        // As the treedepth is 1 mean their is no parent folder so we will not set any folder name here
                        setObjSelectedDestinationFolder({});
                        // yahan folder nai koi because parent sheet hai to folder empty h g
                    }else{
                        // workspace treeDepth > 1 mean folders
                        setObjSelectedDestinationWorkSpace({
                            value: objParentNode.worspace_id,
                            label: objParentNode.workspace_name,
                            name: objParentNode.workspace_name,
                            id: objParentNode.worspace_id,
                        })
                        setObjSelectedDestinationFolder({
                            value: objParentNode.value,
                            label: objParentNode.label,
                            name: objParentNode.label,
                            id: objParentNode.value,
                        })
                        
                    }
                }}
                
                onExpand={onExpand}
                
            // checkModel=''
            />
            
        </Col>


    );
};


export default DestinationDynamicTreeSection;

