// import React, { useState, useRef } from 'react';
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import { Col } from 'reactstrap';
// import { useDispatch, useSelector } from "react-redux";
// import { unwrapResult } from "@reduxjs/toolkit";
// import Select from "react-select"
// import makeAnimated from "react-select/animated";
// import { useAppDispatch } from '../../../../../features/storeConfig/store';
// import Card, {
//     CardActions,
//     CardBody,
//     CardFooter,
//     CardFooterLeft,
//     CardFooterRight,
//     CardHeader,
//     CardLabel,
//     CardSubTitle,
//     CardTitle,
// } from '../../../../../components/bootstrap/Card';
// import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';
// import "./stylesDynamicTree.css";
// const animatedComponents = makeAnimated();

// import 'react-folder-tree/dist/style.css';
import Button from '../../../../../components/bootstrap/Button';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
interface ISourceDynamicTreeSectionProps {
    formattedWorkSpacesArr: any;
    setFormattedWorkSpacesArr: any;
    fetchWorkSpaceFoldersByWorkSpaceIdHandler: any;
    fetchWorkSpaceFolderSheetsByFolderIdHandler: any;
    handleOnChangeSourceSheet: any;
    setObjSelectedSourceWorkSpace: any;
    setObjSelectedSourceFolder: any;
}

const SourceDynamicTreeSection: FC<ISourceDynamicTreeSectionProps> = (props) => {
    const {
        formattedWorkSpacesArr,
        setFormattedWorkSpacesArr,
        fetchWorkSpaceFoldersByWorkSpaceIdHandler,
        fetchWorkSpaceFolderSheetsByFolderIdHandler,
        handleOnChangeSourceSheet, setObjSelectedSourceWorkSpace,
        setObjSelectedSourceFolder
    } = props;


    // const [nodes, setNodes] = useState<any>([
    //     {
    //         value: 'favorite-empires',
    //         label: 'Favorite Empires',
    //         children: [
    //             {
    //                 value: 'classical-era',
    //                 label: 'Classical Era',
    //                 children: [
    //                     {
    //                         value: 'persian',
    //                         label: 'First Persian Empire',
    //                     },
    //                     {
    //                         value: 'qin',
    //                         label: 'Qin Dynasty',
    //                     },
    //                     {
    //                         value: 'spqr',
    //                         label: 'Roman Empire',
    //                     },
    //                 ],
    //             },
    //             {
    //                 value: 'medieval-era',
    //                 label: 'Medieval Era',
    //                 children: [
    //                     {
    //                         value: 'abbasid',
    //                         label: 'Abbasid Caliphate',
    //                     },
    //                     {
    //                         value: 'byzantine',
    //                         label: 'Byzantine Empire',
    //                     },
    //                     {
    //                         value: 'holy-roman',
    //                         label: 'Holy Roman Empire',
    //                     },
    //                     {
    //                         value: 'ming',
    //                         label: 'Ming Dynasty',
    //                     },
    //                     {
    //                         value: 'mongol',
    //                         label: 'Mongol Empire',
    //                     },
    //                 ],
    //             },
    //             {
    //                 value: 'modern-era',
    //                 label: 'Modern Era',
    //                 children: [
    //                     {
    //                         value: 'aztec',
    //                         label: 'Aztec Empire',
    //                     },
    //                     {
    //                         value: 'british',
    //                         label: 'British Empire',
    //                     },
    //                     {
    //                         value: 'inca',
    //                         label: 'Inca Empire',
    //                     },
    //                     {
    //                         value: 'qing',
    //                         label: 'Qing Dynasty',
    //                     },
    //                     {
    //                         value: 'russian',
    //                         label: 'Russian Empire',
    //                     },
    //                     {
    //                         value: 'spanish',
    //                         label: 'Spanish Empire',
    //                     },
    //                 ],
    //             },
    //         ],
    //     },
    // ]);

    const [checked, setChecked] = useState<any>([
        // 'persian',
        // 'spqr',
        // 'byzantine',
        // 'holy-roman',
        // 'inca',
    ]);
    const [expanded, setExpanded] = useState([
        // 'favorite-empires',
        // 'classical-era',
        // 'medieval-era',
    ]);

    // const onCheck1 = (value: any) => {
    //     console.log('onCheck1')
    //     console.log('onCheck1')
    //     console.log('onCheck1')
    //     console.log(value)
    //     setChecked(value);
    // };

    const onExpand = (value: any, targetNode: any) => {
        console.log("formattedWorkSpacesArr in onExpand")
        console.log("formattedWorkSpacesArr in onExpand")
        console.log(formattedWorkSpacesArr)
        console.log("value in onExpand")
        console.log("value in onExpand")
        console.log(value)
        setExpanded(value);
        console.log("targetNode in onExpand")
        console.log("targetNode in onExpand")
        console.log(targetNode)
        console.log("targetNode.value in onExpand")
        console.log("targetNode.value in onExpand")
        console.log(targetNode.value);
        // if(targetNode.checked && Object.keys(targetNode.parent).length === 0 &&  targetNode.treeDepth === 0 ){
        if(Object.keys(targetNode.parent).length === 0){
            // workspace
            fetchWorkSpaceFoldersByWorkSpaceIdHandler(targetNode.value, "source");
            // setObjSelectedSourceWorkSpace({
            //     value: targetNode.value,
            //     label: targetNode.label,
            //     name: targetNode.label,
            //     id: targetNode.value,
            // })
        }
        // if (Array.isArray(value)) {
        //     // workspace or folder
        //     if (value.length === 1) {
        //         // workspace
        //         fetchWorkSpaceFoldersByWorkSpaceIdHandler(value[0], "source");
        //         setObjSelectedSourceWorkSpace({
        //             value: targetNode.value,
        //             label: targetNode.label,
        //             name: targetNode.label,
        //             id: targetNode.value,
        //         })
        //     } else if (value.length > 1) {
        //         // folders or sub folders
        //         // const workspace_id = value[0];
        //         // const { treeDepth, } = targetNode;
        //         // // if(targetNode.expanded){
        //         // //     fetchWorkSpaceFolderSheetsByFolderIdHandler(targetNode.value,"source",treeDepth,workspace_id);
        //         // // }

        //     }
        // } else {
        //     // sheet id
        // }
    };

    const btnOnClickAddNewFolder = () => {

        // console.log("nodes in btnOnClickAddNewFolder")
        // console.log("nodes in btnOnClickAddNewFolder")
        // console.log(nodes)
        // const newEmpire = {
        //     value: `new-empire ${new Date()}`,
        //     label: `New Empire ${new Date()}`,
        //     children: [
        //         {
        //             value: `1 ${new Date()}`,
        //             label: 'First 1 Empire',
        //         },
        //         {
        //             value: `2 ${new Date()}`,
        //             label: '2 Dynasty',
        //         },
        //         {
        //             value: `3 ${new Date()}`,
        //             label: '3 Empire',
        //         },
        //     ],
        // };
        // const emptyEmpire = {
        //     value: `empty-empire ${new Date()}`,
        //     label: `empty Empire ${new Date()}`,
        //     children: []
        // }
        // const theEmpire = {
        //     value: `theEmpire-empire ${new Date()}`,
        //     label: `theEmpire Empire ${new Date()}`,
        //     children: [
        //         {
        //             value: `theEmpire${new Date()}`,
        //             label: 'First 1 Empire',
        //         },
        //         {
        //             value: `2theEmpire ${new Date()}`,
        //             label: '2 Dynasty',
        //         },
        //         {
        //             value: `3theEmpire ${new Date()}`,
        //             label: '3 Empire',
        //             children: [
        //                 {
        //                     value: `sub${new Date()}`,
        //                     label: 'First 1 Empire',
        //                 },
        //                 {
        //                     value: `2sub ${new Date()}`,
        //                     label: '2 Dynasty',
        //                 },
        //                 {
        //                     value: `3sub ${new Date()}`,
        //                     label: '3 Empire',
        //                     children: [
        //                         {
        //                             value: `4th Sub${new Date()}`,
        //                             label: 'First 1 Empire',
        //                         },
        //                         {
        //                             value: `24th Sub ${new Date()}`,
        //                             label: '2 Dynasty',
        //                         },
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // };

        // // Make a copy of the existing 'nodes' state
        // // const nodesCopy = [...nodes];
        // const nodesCopy = structuredClone(nodes)
        // console.log('nodesCopy[0]')
        // console.log(nodesCopy[0])
        // nodesCopy[0].children.push(newEmpire)
        // nodesCopy[0].children.push(emptyEmpire)
        // nodesCopy[0].children.push(theEmpire)
        // console.log('nodesCopy')
        // console.log(nodesCopy)
        // setNodes(nodesCopy);

    }

    return (
        <Col md="12">
            <CheckboxTree
                checked={checked}
                expanded={expanded}
                nodes={formattedWorkSpacesArr}
                onlyLeafCheckboxes
                onCheck={(_checked:any, node:any) => {
                    // setChecked(_checked)
                    // console.log('_checked')
                    // console.log(_checked)
                    setChecked([node.value])
                    console.log('node in onCheck in Source')
                    console.log(node)
                    console.log('node.value in onCheck in Source')
                    console.log(node.value)
                    const _objSelectedSheetData = {
                        value: node.value,
                        label: node.label,
                        name: node.label,
                        id: node.value,
                    }
                    handleOnChangeSourceSheet(_objSelectedSheetData);
                    const objParentNode = node.parent;
                    if(node.treeDepth === 1){
                        // workspace treeDepth 1
                        setObjSelectedSourceWorkSpace({
                            value: objParentNode.value,
                            label: objParentNode.label,
                            name: objParentNode.label,
                            id: objParentNode.value,
                        })
                        setObjSelectedSourceFolder({});
                        // As the treedepth is 1 mean their is no parent folder so we will not set any folder name here

                    }else{
                        // workspace treeDepth > 1 mean folders
                        setObjSelectedSourceWorkSpace({
                            value: objParentNode.worspace_id,
                            label: objParentNode.workspace_name,
                            name: objParentNode.workspace_name,
                            id: objParentNode.worspace_id,
                        })
                        setObjSelectedSourceFolder({
                            value: objParentNode.value,
                            label: objParentNode.label,
                            name: objParentNode.label,
                            id: objParentNode.value,
                        })
                        
                    }
                }}
                
                onExpand={onExpand}

            />
            
        </Col>


    );
};


export default SourceDynamicTreeSection;

