import React, { FC, useCallback, useContext, useState, Fragment } from 'react';
import Spinner from '../../components/bootstrap/Spinner';


interface ILoadingSpninnerProps {
    subClass?: string;
    size?: any;
    color?: any;
    tag?: any;
}
const LoadingSpninner: FC<ILoadingSpninnerProps> = (props) => {
    const { subClass, size, color, tag } = props;
    return (

        <div className={`${subClass}`}>
            <Spinner
                tag={tag} // 'div' || 'span'
                color={color} // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
                // isGrow={ Boolean }
                // isSmall={ Boolean }
                size={size} // Example: 10, '3vh', '5rem' etc.
                // inButton={ Boolean || String} // true || false || 'onlyIcon'
            />
        </div>



    );
};


export default LoadingSpninner;
