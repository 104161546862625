import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
// import { demoPagesMenu } from '../../../../menu';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import validate from './helper/editPagesValidate';
import showNotification from '../../../../components/extras/showNotification';
import Icon from '../../../../components/icon/Icon';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../../hooks/useDarkMode';
import Spinner from '../../../../components/bootstrap/Spinner';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Avatar from '../../../../components/Avatar';
import USERS from '../../../../common/data/userDummyData';
import CommonDesc from '../../../../common/other/CommonDesc';
import Label from '../../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import AuthContext from '../../../../contexts/authContext';
import AccountSettingsForm from './components/AccountSettingsForm';
import LoadingSpninner from '../../../../utils/loading/LoadingSpninner';
const _objInitialValues = {
    name: "",
    email: "",
    is_change_password_enabled: false,
    password: "",
}
const AccountSettings = () => {

	const { objUser } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [objInitialValues, setObjInitialValues] = useState(_objInitialValues);
	const [passwordChangeCTA, setPasswordChangeCTA] = useState<boolean>(false);

	console.log('objUser in AccountSettings');
	console.log(objUser);
	useEffect(() => {
		if(objUser !== undefined && Object.keys(objUser).length > 0){
			let __ObjInitialValues = {
				name: objUser.full_name,
				// address: "",
				email: objUser.email,
				is_change_password_enabled: false,
				password: "",
			};
			setObjInitialValues(__ObjInitialValues);
		}
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[objUser]);
	
	
	
	
	return (
		<PageWrapper 
			title={'Account Settings'}
			isProtected={false}
			page_auth_type={'dashboard_page'}
			checkAuth={true}
			isRedirect={true}
		>
			<Page>
				<div className='row h-100 align-content-start'>
					{
						objUser !== undefined && Object.keys(objUser).length > 0 && objInitialValues.email !== '' ?
						<AccountSettingsForm 
							passwordChangeCTA={passwordChangeCTA}
							setPasswordChangeCTA={setPasswordChangeCTA}
							isLoading={isLoading} 
							setIsLoading={setIsLoading}
							objInitialValues={objInitialValues}
							setObjInitialValues={setObjInitialValues}
						/>
						: <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
					}
				</div>
				
			</Page>
		</PageWrapper>
	);
};

export default AccountSettings;
