import { createSlice } from "@reduxjs/toolkit";
import {
  getOAuthLoginUrl,
  smartSheetOAuthCallback
} from "./smartSheetOAuthActions";
import type { PayloadAction } from '@reduxjs/toolkit'


type InitialStateType = {
  objSmartSheetOAuthLoginUrlData?: any;
  objSmartSheetOAuthCallBackSuccess?: any;
  loading?: boolean;
}
const initialState: InitialStateType = {
  
};

const smartSheetOAuthSlice: any = createSlice({
  name: "smartSheetOAuth",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      // getOAuthLoginUrl
      .addCase(getOAuthLoginUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOAuthLoginUrl.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSmartSheetOAuthLoginUrlData = action.payload;
      })
      .addCase(getOAuthLoginUrl.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSmartSheetOAuthLoginUrlData = action.payload;
      })
      // smartSheetOAuthCallback
      .addCase(smartSheetOAuthCallback.pending, (state) => {
        state.loading = true;
      })
      .addCase(smartSheetOAuthCallback.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSmartSheetOAuthCallBackSuccess = action.payload;
      })
      .addCase(smartSheetOAuthCallback.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSmartSheetOAuthCallBackSuccess = action.payload;
      })
      
    
  },
});

// export const { logout } = smartSheetOAuthSlice.actions;

export default smartSheetOAuthSlice.reducer;
