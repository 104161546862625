import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../../utils/constants/misc";
import { SMART_SHEET_SERVER } from "../../../utils/constants/misc";



export const fetchSheetWorkSpaces = createAsyncThunk("smartSheet/workSpace/fetchSheetWorkSpaces", async (_:void, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.get(`${BACKEND_API_URL}${SMART_SHEET_SERVER}/get-all-workspaces`);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchWorkSpaceByWorkSpaceId = createAsyncThunk("smartSheet/workSpace/fetchWorkSpaceByWorkSpaceId", async (work_space_id:any, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.get(`${BACKEND_API_URL}${SMART_SHEET_SERVER}/get-workspace-by-id/${work_space_id}`);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// export const clearUserSmartSheetWorkSpaces = createAsyncThunk(
//   "smartSheet/workSpace/clearUserSmartSheetWorkSpaces",
//   async (values, thunkApi) => {
//     try {
//       const res = {
//         objOrganizations: [],total_organizations:0, error: false
//       };
//       return res;
//       // return Promise.resolve(res);
//     } catch (error) {
//       return thunkApi.rejectWithValue(error);
//     }
//   }
// );



