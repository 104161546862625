import React, { FC, Fragment } from "react";
import ScheduledAutomationsListItemBlock from './ScheduledAutomationsListItemBlock'
interface ILoadScheduledAutomationsListProps {
    objScheduleAutomations: any;
    handleOnClickDeleteScheduledAutomation: any;
    darkModeStatus: boolean;
}
const LoadScheduledAutomationsList: FC<ILoadScheduledAutomationsListProps> = (props) => {
    const { objScheduleAutomations, handleOnClickDeleteScheduledAutomation, darkModeStatus
    } = props;
    return (
        <Fragment>
            
            <ScheduledAutomationsListItemBlock
                list={props.objScheduleAutomations.objScheduleAutomations.objScheduleAutomations}
                darkModeStatus={props.darkModeStatus}
                handleOnClickDeleteScheduledAutomation={props.handleOnClickDeleteScheduledAutomation}
            />
        </Fragment>
    )
}
export default LoadScheduledAutomationsList
