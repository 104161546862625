import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../../utils/constants/misc";
import { SMART_SHEET_SERVER } from "../../../utils/constants/misc";



export const fetchWorkSpaceFoldersByWorkSpaceId = createAsyncThunk("smartSheet/workSpaceFolders/fetchWorkSpaceFoldersByWorkSpaceId", async (work_space_id:any, thunkApi) => {
  try {
    
    const res : AxiosResponse = await axios.get(`${BACKEND_API_URL}${SMART_SHEET_SERVER}/fetch-workspace-folders-by-workspace-id/${work_space_id}`);
    // console.log(res);
    // return res;
    return res;
    // return Promise.resolve(res);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
    
  }
});

export const fetchWorkSpaceFolderSheetsByFolderId = createAsyncThunk("smartSheet/workSpaceFolders/fetchWorkSpaceFolderSheetsByFolderId", async (folder_id:any, thunkApi) => {
  try {
    
    const res : AxiosResponse = await axios.get(`${BACKEND_API_URL}${SMART_SHEET_SERVER}/fetch-folder-by-folder-id/${folder_id}`);
    // console.log(res);
    // return res;
    return res;
    // return Promise.resolve(res);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
    
  }
});

export const fetchWorkSpaceFolderSheetColumnsBySheetId = createAsyncThunk("smartSheet/workSpaceFolders/fetchWorkSpaceFolderSheetColumnsBySheetId", async (sheet_id:any, thunkApi) => {
  try {
    
    const res : AxiosResponse = await axios.get(`${BACKEND_API_URL}${SMART_SHEET_SERVER}/fetch-sheet-by-sheet-id/${sheet_id}`);
    return res;
    // return Promise.resolve(res);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
    
  }
});





