import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginFormFormikWrapper from './LoginFormFormikWrapper';
import { userLogin } from '../../../../../features/user/userActions';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from '../../../../../features/storeConfig/store';
import axios from 'axios';
import showNotification from '../../../../../components/extras/showNotification';
const objInitialValues = {
    email: "",
    password: "",
};
const LoginFormJWTSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string()
      .min(8, "Password is too short!(min 8 characters are required)")
      .required("Password is required"),
});

const LoginForm: FC = () => {


    const navigate = useNavigate();

    // const dispatch = useDispatch<any>();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmitForm = async (values : any) => {
        console.log("values in onSubmitForm");
        console.log(values);
        const objPostData = {
          ...values,
        };
        setIsLoading(true);
        dispatch(userLogin(objPostData))
          .then(unwrapResult)
          .then((originalPromiseResult : any) => {
            setIsLoading(false);
            console.log("originalPromiseResult in userLogin");
            console.log("originalPromiseResult in userLogin");
            console.log("originalPromiseResult in userLogin");
            console.log(originalPromiseResult);
            const response = originalPromiseResult;
            console.log("response.data in userLogin");
            console.log("response.data in userLogin");
            console.log("response.data in userLogin");
            console.log(response.data);
            if (response.data.error === false) {
                // showNotification(response.data.msg,'','success')
                axios.defaults.headers.common["Authorization"] = `${response.data.token}`;
                localStorage.setItem("token", response.data.token);
                let redirectUrl = '/';
                navigate(redirectUrl);
                
                    // dispatch(auth())
                    // .then(unwrapResult)
                    // .then((originalPromiseResultAuth) => {
                    //     setIsLoading(false);
                    //     console.log("originalPromiseResultAuth in auth");
                    //     console.log("originalPromiseResultAuth in auth");
                    //     console.log("originalPromiseResultAuth in auth");
                    //     console.log(originalPromiseResultAuth);
                    //     const { redirectTo } = queryString.parse(location.search);
                    //     console.log("redirectTo in auth");
                    //     console.log("redirectTo in auth");
                    //     console.log("redirectTo in auth");
                    //     console.log(redirectTo);
                    //     navigate(
                    //     redirectTo === null || redirectTo === undefined
                    //         ? "/email-verification"
                    //         : redirectTo
                    //     );
                    // })
                    // .catch((rejectedValueOrSerializedErrorAuth) => {
                    //     setIsLoading(false);
                    //     showMsg("error", "Please correct the errors.");
                    //     console.log("rejectedValueOrSerializedErrorAuth");
                    //     console.log("rejectedValueOrSerializedErrorAuth");
                    //     console.log("rejectedValueOrSerializedErrorAuth");
                    //     console.log(rejectedValueOrSerializedErrorAuth);
                    //     // console.log("rejectedValueOrSerializedError.response.data.errors");
                    //     // console.log("rejectedValueOrSerializedError.response.data.errors");
                    //     // console.log("rejectedValueOrSerializedError.response.data.errors");
                    //     // console.log(rejectedValueOrSerializedError.response.data.errors);
                    //     // handle result here
                    // });
                
            } else {
                showNotification(response.data.msg,'','danger')
            }
          })
          .catch((rejectedValueOrSerializedError: any) => {
            setIsLoading(false);
            showNotification("Ops something went wrong please try again",'','danger')
            console.log("rejectedValueOrSerializedError");
            console.log("rejectedValueOrSerializedError");
            console.log("rejectedValueOrSerializedError");
            console.log(rejectedValueOrSerializedError);
          });
    };
    return (
        <Formik
            initialValues={objInitialValues}
            validationSchema={LoginFormJWTSchema}
            onSubmit={onSubmitForm}
        >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={"LoginForm"} autoComplete="off" className='row g-4'>
                    <LoginFormFormikWrapper
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}

                    />
                </Form>
            )}
        </Formik>



    );
};


export default LoginForm;
