import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Calendar, dayjsLocalizer, View as TView, Views } from 'react-big-calendar';
import classNames from 'classnames';
import dayjs from 'dayjs';
// import { Calendar as DatePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
// import SubHeader, {
// 	SubHeaderLeft,
// 	SubHeaderRight,
// 	SubheaderSeparator,
// } from '../../../layout/SubHeader/SubHeader';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { useAppDispatch, useAppSelector } from '../../../features/storeConfig/store';
import { unwrapResult } from '@reduxjs/toolkit';
import CommonDesc from '../../../common/other/CommonDesc';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';



// import { demoPagesMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
// import ScheduleAutomationModal from './components/ScheduleAutomationModal/ScheduleAutomationModal';
import LoadScheduledAutomationsList from './components/LoadScheduledAutomationsList';
import LoadingSpninner from '../../../utils/loading/LoadingSpninner';
import showNotification from '../../../components/extras/showNotification';
import { clearScheduledAutomations, deleteScheduleAutomationById, deleteScheduleAutomationFromTable, fetchScheduledAutomations } from '../../../features/scheduleAutomations/scheduleAutomationsActions';
import AutomationManagerSearchForm from './components/AutomationManagerSearchForm/AutomationManagerSearchForm';
const SKIP_AM = 0;
const LIMIT_AM = 10;
const AutomationManager = () => {
	const { darkModeStatus } = useDarkMode();
	const [txtAutomationJobName, setTxtAutomationJobName] = useState("");
	const [skip, setSkip] = useState(SKIP_AM);
	const [limit, setLimit] = useState(LIMIT_AM);
	const [isLoadingScheduledAutomations, setIsLoadingScheduledAutomations] = useState<boolean>(false);
	const [isDeletingScheduledAutomation, setIsDeletingScheduledAutomation] = useState<boolean>(false);
	
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	useEffect(() => {
		loadMoreScheduledAutomation(txtAutomationJobName, skip, limit);
		return () => {
			dispatch(clearScheduledAutomations());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	const loadMoreScheduledAutomation = (_txtAutomationJobName: string, _skip: number, _limit: number) => {
		setIsLoadingScheduledAutomations(true);
		dispatch(fetchScheduledAutomations({ skip: _skip, limit: _limit, txtAutomationJobName:_txtAutomationJobName }))
			.then(unwrapResult)
			.then((originalPromiseResult) => {
				setIsLoadingScheduledAutomations(false);
				// handle result here
				const response = originalPromiseResult;
				console.log("response in fetchScheduledAutomations");
				console.log(response);
			}).catch((rejectedValueOrSerializedError) => {
				setIsLoadingScheduledAutomations(false);
				console.log("rejectedValueOrSerializedError in fetchScheduledAutomations");
				console.log(rejectedValueOrSerializedError);
			});
	};

	const btnOnClickLoadMoreScheduledAutomation = () => {
		let updSkip: number = skip + limit;
		// setSkip(skip => skip + limit);
		setSkip(updSkip);
		loadMoreScheduledAutomation(txtAutomationJobName, updSkip, limit);
	};

	const btnOnClickSearch = () => {
		let updSkip: number = 0;
		// setSkip(skip => skip + limit);
		setSkip(updSkip);
		dispatch(clearScheduledAutomations());
		loadMoreScheduledAutomation(txtAutomationJobName, updSkip, limit);
	};

	const btnOnClickReset = () => {
		resetSearch();
		loadMoreScheduledAutomation("",SKIP_AM, LIMIT_AM);
	};

	const resetSearch = () => {
		setTxtAutomationJobName("");
		let updSkip: number = 0;
		// setSkip(skip => skip + limit);
		setSkip(updSkip);
		dispatch(clearScheduledAutomations());
	};

	

	const handleOnClickDeleteScheduledAutomation = (objScheduledAutomation:any) =>{
		console.log('objScheduledAutomation in handleOnClickDeleteScheduledAutomation');
        console.log('objScheduledAutomation in handleOnClickDeleteScheduledAutomation');
        console.log(objScheduledAutomation);
		const conf = window.confirm('Are you sure, You want to remove this automation schedule?');
		if (conf) {
			const objPostData = {
				id: objScheduledAutomation._id,
			}
			setIsDeletingScheduledAutomation(true);
			dispatch(deleteScheduleAutomationById(objPostData))
				.then(unwrapResult)
				.then((originalPromiseResult) => {
					setIsDeletingScheduledAutomation(false);
					// handle result here
					const response = originalPromiseResult;
					console.log("response in fetchScheduledAutomations");
					console.log(response);
					dispatch(deleteScheduleAutomationFromTable(objScheduledAutomation))
				}).catch((rejectedValueOrSerializedError) => {
					setIsDeletingScheduledAutomation(false);
					console.log("rejectedValueOrSerializedError in fetchScheduledAutomations");
					console.log(rejectedValueOrSerializedError);
				});
		}
        // dispatch(toggleCheckBoxDesignAutomationsHistory(objToggleData))
    }

	const { objScheduleAutomations  }: any = useAppSelector(state => ({
		objScheduleAutomations: state.scheduleAutomations.scheduleAutomations,
	}));

	console.log('objScheduleAutomations in render')
	console.log('objScheduleAutomations in render')
	console.log('objScheduleAutomations in render')
	console.log(objScheduleAutomations)
	console.log('objScheduleAutomations.objScheduleAutomations in render')
	console.log('objScheduleAutomations.objScheduleAutomations in render')
	console.log('objScheduleAutomations.objScheduleAutomations in render')
	console.log(objScheduleAutomations.objScheduleAutomations)
	

	
	return (
		<PageWrapper 
			title={"Automation Manager"}
			isProtected={false}
			page_auth_type={'dashboard_page'}
			checkAuth={true}
			isRedirect={true}
		>
			<Page container='fluid'>
				<div className='row h-100'>

					<div className='col-xxl-12 col-xl-12 col-lg-12'>
						<Card stretch={true}>
							<CardHeader borderSize={1}>
								<CardLabel icon='FormatListBulleted' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Automation Manager
									</CardTitle>
								</CardLabel>
								<CardActions>
									<></>
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive'>
								<AutomationManagerSearchForm 
									btnOnClickSearch={btnOnClickSearch}
									btnOnClickReset={btnOnClickReset}
									txtAutomationJobName={txtAutomationJobName}
									setTxtAutomationJobName={setTxtAutomationJobName}
								/>
								<table className='table table-modern'>
									<thead>
										<tr>
											<th>Automation Job Name</th>
											<th>Start Date</th>
											<th>End Date</th>
											<th>Frequency</th>
											<th>Created Date</th>
											<th>Action</th>
										</tr>
									</thead>
									{
										objScheduleAutomations !== undefined && (Object.keys(objScheduleAutomations).length > 0) ?
										<tbody>
											<LoadScheduledAutomationsList
												objScheduleAutomations={objScheduleAutomations}
												darkModeStatus={darkModeStatus}
												handleOnClickDeleteScheduledAutomation={handleOnClickDeleteScheduledAutomation}
											/>
										</tbody>
											: null
									}
									{/* <tbody>
										<tr >
											<td>
												May 24o 2023, 10:30 am
											</td>
											<td>
												<div>
													<div>Alison Berry</div>
													<div className='small text-muted'>
														alisonberry@site.com
													</div>
												</div>
											</td>
											<td>
												<div className='d-flex'>
													
													<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
														Grace Buckland
													</div>
												</div>
											</td>
											<td>6th Ice Skating</td>
											<td>
												<Button
													isOutline={!darkModeStatus}
													color='danger'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Delete'
													// onClick={handleUpcomingEdit}>
												>
													Delete
												</Button>
											</td>
											
											
										</tr>
									</tbody> */}
								</table>

								{
									isLoadingScheduledAutomations ? <LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' /> : null
								}
								{
									isLoadingScheduledAutomations === false && objScheduleAutomations.objScheduleAutomations !== undefined && objScheduleAutomations.objScheduleAutomations.total_schedule_automations !== 0 && objScheduleAutomations.objScheduleAutomations.total_schedule_automations > objScheduleAutomations.objScheduleAutomations.objScheduleAutomations.length  ?
										<div className='text-center'>

											<Button
												color='info'
												icon='Schedule'
												isLight
												onClick={() => btnOnClickLoadMoreScheduledAutomation()}
											>
												Load more
											</Button>
										</div>
										: null
								}
							</CardBody>
							
						</Card>




					</div>
				</div>
				{/* <ScheduleAutomationModal
					isShowScheduleAutomationModal={isShowScheduleAutomationModal}
					setIsShowScheduleAutomationModal={setIsShowScheduleAutomationModal}
				/> */}
			</Page>
		</PageWrapper>
	);
};

export default AutomationManager;
