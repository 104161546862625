import React, { FC, Fragment, useContext, useState } from 'react';
// @ts-ignore

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import Button from '../../../../../components/bootstrap/Button';
import useDarkMode from '../../../../../hooks/useDarkMode';
import CommonAlert from '../../../../../common/alerts/CommonAltert';
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from '../../../../../features/storeConfig/store';
import showNotification from '../../../../../components/extras/showNotification';
import { getOAuthLoginUrl } from '../../../../../features/smartSheet/smartSheetOAuth/smartSheetOAuthActions';
import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';
import AuthContext from '../../../../../contexts/authContext';

const ConnectToSmartSheetSection: FC = () => {
	const { objUser } = useContext(AuthContext);
	const [isGeneratingSmartSheetOAuthLink, setIsGeneratingSmartSheetOAuthLink] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const btnOnClickConnectToSmartSheet = () => {
        setIsGeneratingSmartSheetOAuthLink(true);
        dispatch(getOAuthLoginUrl())
          .then(unwrapResult)
          .then((originalPromiseResult : any) => {
            setIsGeneratingSmartSheetOAuthLink(false);
            console.log("originalPromiseResult in getOAuthLoginUrl");
            console.log(originalPromiseResult);
            const response = originalPromiseResult;
            console.log("response.data in getOAuthLoginUrl");
            console.log(response.data);
            if (response.data.error === false) {
				window.location.href = response.data.authorizationUri;
                // showNotification(response.data.msg,'','success')
                // dispatch(updateAuthUserData(response.data.objUser))
            } else {
                showNotification(response.data.msg,'','danger');
            }
          })
          .catch((rejectedValueOrSerializedError: any) => {
            setIsGeneratingSmartSheetOAuthLink(false);
            showNotification("Ops something went wrong please try again",'','danger')
            console.log("rejectedValueOrSerializedError");
            console.log(rejectedValueOrSerializedError);
          });
	}
	

	
	return (
		<>
			<Card stretch>
				<CardHeader>
					<CardLabel icon='FactCheck' iconColor='info'>
						<CardTitle tag='div' className='h5'>
							Smartsheet
						</CardTitle>
					</CardLabel>
					<CardActions>
						{
							isGeneratingSmartSheetOAuthLink === false ?
							<Button
								color='info'
								icon='Link'
								isLight
								// onClick={() => setModalStatus(true)}
								onClick={() => {
									btnOnClickConnectToSmartSheet();
								}}
							>
								Connect To Smartsheet
							</Button>
							: 
							<LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' />
						}
					</CardActions>
				</CardHeader>
				<CardBody>
					{
						objUser !== undefined && Object.keys(objUser).length > 0 ?
						<Fragment>
							{
								objUser.smartsheet_token_data !== undefined && Object.keys(objUser.smartsheet_token_data).length > 0 ?
								<CommonAlert color='success' icon='CheckCircle' className='mt-20'>
									Smartsheet Connected
								</CommonAlert>
								: 
								<CommonAlert color='danger' icon='Cancel'>
									Smartsheet Not Connected
								</CommonAlert>
							}
						</Fragment>
						: 
						<LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' />
					}
				</CardBody>
			</Card>
			
		</>
	);
};

export default ConnectToSmartSheetSection;
