import { createSlice } from "@reduxjs/toolkit";
import {
  userLogin,
  auth,
  logout,
  authUpdateUser,
  updateAuthUserData,
  savePersonalSettings,
} from "./userActions";
// import type { RootState } from '../../features/storeConfig/store'
import type { PayloadAction } from '@reduxjs/toolkit'


// import { getUserDetails, registerUser,  } from "./userActions";

// initialize userToken from local storage
// const userToken = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;
type InitialStateType = {
  objLoginFormSuccess?: any;
  objSavePersonalSettingsFormSuccess?: any;
  objUser?: any;
  loading?: boolean;
}
const initialState: InitialStateType = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,

};

const userSlice: any = createSlice({
  name: "user",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.objLoginFormSuccess = null;
      })
      .addCase(userLogin.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objLoginFormSuccess = action.payload;
      })
      .addCase(userLogin.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objLoginFormSuccess = action.payload;
      })
      .addCase(auth.pending, (state) => {
        state.loading = true;
      })
      .addCase(auth.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objUser = action.payload;
      })
      .addCase(auth.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objUser = action.payload;
      })
      // objSavePersonalSettingsFormSuccess
      .addCase(savePersonalSettings.pending, (state) => {
        state.loading = true;
        state.objSavePersonalSettingsFormSuccess = null;
      })
      .addCase(savePersonalSettings.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSavePersonalSettingsFormSuccess = action.payload;
      })
      .addCase(savePersonalSettings.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSavePersonalSettingsFormSuccess = action.payload;
      })
      // updateAuthUserData
      .addCase(updateAuthUserData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objUser = action.payload;
      })
    // login
    // [userLogin.pending]: (state:RootState) => {
    //   state.loading = true;
    //   state.objLoginFormSuccess = null;
    // },
    // [userLogin.fulfilled]: (state :RootState, action: PayloadAction<any>) => {
    //   state.loading = false;
    //   state.objLoginFormSuccess = action.payload;
    // },
    // [userLogin.rejected]: (state :RootState, action: PayloadAction<any>) => {
    //   state.loading = false;
    //   state.objLoginFormSuccess = action.payload;
    // },
    // // auth
    // [auth.pending]: (state:RootState) => {
    //   state.loading = true;
    // },
    // [auth.fulfilled]: (state:RootState , action: PayloadAction<any>) => {
    //   state.loading = false;
    //   state.objUser = action.payload;
    // },
    // [auth.rejected]: (state:RootState , action: PayloadAction<any>) => {
    //   state.loading = false;
    //   state.objUser = action.payload;
    // },




    //
    // authUpdateUser
    // [authUpdateUser.pending]: (state) => {
    //   state.loading = true;
    // },
    // [authUpdateUser.fulfilled]: (state, { payload }) => {
    //   state.loading = false;
    //   state.objAuthUpdateUserSuccess = payload;
    // },
    // [authUpdateUser.rejected]: (state, { payload }) => {
    //   state.loading = false;
    //   state.objAuthUpdateUserSuccess = payload;
    // },

    // // updateAuthUserData

    // [updateAuthUserData.fulfilled]: (state, { payload }) => {
    //   state.loading = false;
    //   state.objUser = payload;
    // },
  },
});

// export const { logout } = userSlice.actions;

export default userSlice.reducer;
