import React, { FC, Fragment, useContext, useState } from 'react';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
// import { demoPagesMenu } from '../../../../../menu';
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
    SubheaderSeparator,
} from '../../../../../layout/SubHeader/SubHeader';
import Page from '../../../../../layout/Page/Page';
import showNotification from '../../../../../components/extras/showNotification';
import Icon from '../../../../../components/icon/Icon';
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../../../components/bootstrap/Card';
import Button from '../../../../../components/bootstrap/Button';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Spinner from '../../../../../components/bootstrap/Spinner';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import Breadcrumb from '../../../../../components/bootstrap/Breadcrumb';
import Avatar from '../../../../../components/Avatar';
import USERS from '../../../../../common/data/userDummyData';
import CommonDesc from '../../../../../common/other/CommonDesc';
import Label from '../../../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../../../components/bootstrap/forms/Checks';
import AuthContext from '../../../../../contexts/authContext';
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from '../../../../../features/storeConfig/store';
// import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import AccountSettingsFormFormikWrapper from './AccountSettingsFormFormikWrapper';
import { savePersonalSettings, updateAuthUserData } from '../../../../../features/user/userActions';
const AccountSettingsFormJWTSchema = Yup.object().shape({
    name: Yup.string().required("Your Name is Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Your Email is Required"),
    is_change_password_enabled: Yup.bool().oneOf([true,false], ' Check This'),
    password: Yup.string()
            .when("is_change_password_enabled", {
            is: true,
            then: () => Yup.string().min(8, "Password is too short!(min 8 characters are required)")
                    .required("Password is required")
    })
    

})
interface IAccountSettingsFormProps {
    passwordChangeCTA: boolean,
    setPasswordChangeCTA: any,
    isLoading: boolean,
    setIsLoading: any,
    objInitialValues: any,
    setObjInitialValues: any,
}

const AccountSettingsForm: FC<IAccountSettingsFormProps> = (props) => {
    const { passwordChangeCTA, setPasswordChangeCTA, isLoading, setIsLoading,
        objInitialValues, setObjInitialValues,
    } = props;
    const navigate = useNavigate();
    const { setObjUser } = useContext(AuthContext);
    // const dispatch = useDispatch<any>();
    const dispatch = useAppDispatch();
    const onSubmitForm = async (values: any) => {
        console.log("values in onSubmitForm");
        console.log(values);
        const objPostData = {
            ...values,
        };
        setIsLoading(true);
        dispatch(savePersonalSettings(objPostData))
          .then(unwrapResult)
          .then((originalPromiseResult : any) => {
            setIsLoading(false);
            console.log("originalPromiseResult in savePersonalSettings");
            console.log(originalPromiseResult);
            const response = originalPromiseResult;
            console.log("response.data in savePersonalSettings");
            console.log(response.data);
            if (response.data.error === false) {
                showNotification(response.data.msg,'','success')
                dispatch(updateAuthUserData(response.data.objUser))
                setObjUser({isAuth: true,...response.data.objUser})
            } else {
                showNotification(response.data.msg,'','danger')
            }
          })
          .catch((rejectedValueOrSerializedError: any) => {
            setIsLoading(false);
            showNotification("Ops something went wrong please try again",'','danger')
            console.log("rejectedValueOrSerializedError");
            console.log("rejectedValueOrSerializedError");
            console.log("rejectedValueOrSerializedError");
            console.log(rejectedValueOrSerializedError);
          });
    };
    return (
        <Formik
            initialValues={objInitialValues}
            validationSchema={AccountSettingsFormJWTSchema}
            onSubmit={onSubmitForm}
        >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={"LoginForm"} autoComplete="off" className='row g-4'>
                    <AccountSettingsFormFormikWrapper
                        passwordChangeCTA={passwordChangeCTA}
                        setPasswordChangeCTA={setPasswordChangeCTA}
                        setIsLoading={setIsLoading}
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}

                    />



                </Form>
            )}
        </Formik>

    );
};

export default AccountSettingsForm;
