import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../../utils/constants/misc";
import { SMART_SHEET_OAUTH_SERVER } from "../../../utils/constants/misc";


export const getOAuthLoginUrl = createAsyncThunk("smartSheet/smartSheetOAuth/getOAuthLoginUrl", async (_:void, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.get(`${BACKEND_API_URL}${SMART_SHEET_OAUTH_SERVER}/get-oauth-login-url`);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const smartSheetOAuthCallback = createAsyncThunk("smartSheet/smartSheetOAuth/smartSheetOAuthCallback", async (values: any, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${SMART_SHEET_OAUTH_SERVER}/callback`, values);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});