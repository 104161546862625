import React, { FC, Fragment } from "react"
import AutomationsHistoryObjAutomationListItemBlock from './AutomationsHistoryObjAutomationListItemBlock';
interface IAutomationsHistoryListItemBlockProps {
    list: any;
    handleOnClickSelectCheckBox: any;
    darkModeStatus: boolean;
}
const AutomationsHistoryListItemBlock: FC<IAutomationsHistoryListItemBlockProps> = (props) => {
    const { list, darkModeStatus
    } = props;
    const renderAutomationListItem = () => (
        props.list ?
            props.list.map((objAutomation:any) => (
                <AutomationsHistoryObjAutomationListItemBlock
                    key={objAutomation._id}
                    darkModeStatus={darkModeStatus}
                    handleOnClickSelectCheckBox={props.handleOnClickSelectCheckBox}
                    {...objAutomation}
                />
            ))
            : null
    )
    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <tr className="text-center">
                        <td className="text-center" colSpan={9}> No records found, Please add some Automations first.</td>
                    </tr>
                    : <></>
                : <></>
            }
            {renderAutomationListItem()}
        </Fragment>
    )
}
export default AutomationsHistoryListItemBlock
