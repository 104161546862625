import React, { Fragment, useState } from "react"
import { FormGroup, Label, Row, Col, Input } from "reactstrap"
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-datepicker/dist/react-datepicker.css";
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardFooterLeft,
    CardFooterRight,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../../../components/bootstrap/Card';
import Button, { ButtonGroup } from '../../../../../components/bootstrap/Button';
import moment from "moment";
import showNotification from "../../../../../components/extras/showNotification";
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from "../../../../../features/storeConfig/store";
import LoadingSpninner from "../../../../../utils/loading/LoadingSpninner";
import { saveScheduleAutomations } from "../../../../../features/scheduleAutomations/scheduleAutomationsActions";
const ScheduleAutomationModalBody = (props: any) => {
    const {
        objDesignAutomations, toggle
    } = props;
    const [txtAutomationJobName, setTxtAutomationJobName] = useState<string>("");
    const [radioFrequency, setRadioFrequency] = useState<string>("");
    const [startDateTime, setStartDateTime] = useState(new Date());
    const [endDateTime, setEndDateTime] = useState(new Date());
    const [objFormErrors, setObjFormErrors] = useState<any>({});
    const [isSavingScheduleAutomation, setIsSavingScheduleAutomation] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const calculateMinTime = (date:any) => {
    //     let isToday = moment(date).isSame(moment(), 'day');
    //     if (isToday) {
    //         let nowAddOneHour = moment(new Date()).add({hours: 1}).toDate();
    //         return nowAddOneHour;
    //     }
    //     return moment().startOf('day').toDate(); 
    // }
    const onRadioChange = (event:any) => {
        setRadioFrequency(event.target.value)
    }
    const validateSaveScheduleAutomation = () => {
        let error = false;
        const _objFormErrors: any = {};
        // source
        if (txtAutomationJobName === "") {
            error = true;
            _objFormErrors['txtAutomationJobName'] = 'Please enter your automation job name.';
        }
        // if(Object.keys(objSelectedSourceFolder).length === 0){
        // 	_objFormErrors['objSelectedSourceFolder'] = 'Please select your workspace.';
        // }
        if (radioFrequency === "") {
            error = true;
            _objFormErrors['radioFrequency'] = 'Please select your frequency.';
        }
        console.log('new Date(endDateTime) < new Date(startDateTime)')
        console.log('new Date(endDateTime) < new Date(startDateTime)')
        console.log('new Date(endDateTime) < new Date(startDateTime)')
        console.log(new Date(endDateTime) < new Date(startDateTime))
        if(new Date(endDateTime) < new Date(startDateTime)){
            error = true;
            _objFormErrors['startDateTime'] = 'End Date must be greater then start date.';
        	_objFormErrors['endDateTime'] = 'End Date must be greater then start date.';
        }
        // if (startDateTime === "") {
        // 	error = true;
        // 	_objFormErrors['startDateTime'] = 'Please select your start date.';
        // }
        // if (endDateTime === "") {
        // 	error = true;
        // 	_objFormErrors['endDateTime'] = 'Please select your end date.';
        // }
        setObjFormErrors(_objFormErrors);
        return error;
    }
    const btnOnClickSaveScheduleAutomation = () => {
        const isError = validateSaveScheduleAutomation();
        if (isError === false) {
            const objSeletedData = [];
            for (let index = 0; index < objDesignAutomations.objDesignAutomations.objDesignAutomations.length; index++) {
                for (let indexAutomationList = 0; indexAutomationList < objDesignAutomations.objDesignAutomations.objDesignAutomations[index].objAutomationsList.length; indexAutomationList++) {
                    const elementSub = objDesignAutomations.objDesignAutomations.objDesignAutomations[index].objAutomationsList[indexAutomationList];
                    if (elementSub.is_checked) {
                        objSeletedData.push(elementSub);
                    }
                }
            }
            console.log('objSeletedData in btnOnClickSaveScheduleAutomation')
            console.log(objSeletedData)
            if (objSeletedData.length > 0) {
                const objPostData: any = {
                    txtAutomationJobName,
                    radioFrequency,
                    startDateTime,
                    endDateTime,
                    objAutomationsList: objSeletedData
                }

                console.log('objPostData in btnOnClickSaveScheduleAutomation');
                console.log('objPostData in btnOnClickSaveScheduleAutomation');
                console.log('objPostData in btnOnClickSaveScheduleAutomation');
                console.log(objPostData);
                setIsSavingScheduleAutomation(true);
                dispatch(saveScheduleAutomations(objPostData))
                    .then(unwrapResult)
                    .then((originalPromiseResult: any) => {
                        setIsSavingScheduleAutomation(false);
                        console.log("originalPromiseResult in saveScheduleAutomations");
                        console.log(originalPromiseResult);
                        const response = originalPromiseResult;
                        console.log("response.data in saveScheduleAutomations");
                        console.log(response.data);
                        if (response.data.error === false) {
                            showNotification(response.data.msg, '', 'success')
                            toggle();
                            let redirectUrl = '/automation-manager';
                            navigate(redirectUrl);
                        } else {
                            showNotification(response.data.msg, '', 'danger')
                        }
                    })
                    .catch((rejectedValueOrSerializedError: any) => {
                        setIsSavingScheduleAutomation(false);
                        showNotification("Ops something went wrong please try again", '', 'danger')
                        console.log("rejectedValueOrSerializedError");
                        console.log(rejectedValueOrSerializedError);
                    });

            } else {
                showNotification("Please select some rows in order schedule an automation.", '', 'danger');
            }


        } else {
            showNotification("Please fill out the form first.", '', 'danger')
        }

    }
    console.log('objFormErrors in ScheduleAutomationModalBody');
    console.log('objFormErrors in ScheduleAutomationModalBody');
    console.log('objFormErrors in ScheduleAutomationModalBody');
    console.log(objFormErrors);
    return (
        <Fragment>
            <div className='col-xxl-12 col-xl-12 col-lg-12'>
                <Card stretch tag='form' >

                    <CardHeader>
                        <CardLabel icon='Schedule' iconColor='warning'>
                            <CardTitle tag='div' className='h5'>
                                Schedule Automation
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <Row className='g-4'>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.txtAutomationJobName && "text-danger"}`}>
                                    <Label >Automation Job Name</Label>
                                    <Input
                                        type="text"
                                        name="txtAutomationJobName"
                                        id="txtAutomationJobName"
                                        placeholder="Automation Job Name"
                                        className={`form-control ${objFormErrors.txtAutomationJobName &&
                                            "is-invalid"}`}
                                        onChange={(e) => setTxtAutomationJobName(e.target.value)}
                                        value={txtAutomationJobName}
                                    />
                                    {objFormErrors.txtAutomationJobName ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.txtAutomationJobName}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.startDateTime && "text-danger"}`}>
                                    <Label >Start Date</Label>
                                    <DatePicker
                                        selected={startDateTime}
                                        onChange={(date: any) => setStartDateTime(date)}
                                        showTimeSelect
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        timeIntervals={60}
                                        className={`form-control ${objFormErrors.startDateTime &&
                                            "is-invalid"}`}
                                        minDate={new Date()}
                                    // minTime={calculateMinTime()} //set current time to disable past time
                                    // maxTime={setHours(setMinutes(new Date(), 59), 23)} //this set the max time (i.e. 23:59)
                                    />
                                    {objFormErrors.startDateTime ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.startDateTime}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='g-4'>
                            <Col md="6">
                                <FormGroup tag="fieldset" className={`${objFormErrors.radioFrequency && "text-danger"}`}>
                                    <legend>
                                        Frequency
                                    </legend>
                                    <FormGroup check>
                                        <Input
                                            name="radioFrequency"
                                            type="radio"
                                            value="daily"
                                            checked={radioFrequency === "daily"}
                                            onChange={onRadioChange}
                                        />
                                        {' '}
                                        <Label check>
                                            Daily
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Input
                                            name="radioFrequency"
                                            type="radio"
                                            value="weekly"
                                            checked={radioFrequency === "weekly"}
                                            onChange={onRadioChange}
                                        />
                                        {' '}
                                        <Label check>
                                            Weekly
                                        </Label>
                                    </FormGroup>
                                    <FormGroup
                                        check
                                    >
                                        <Input
                                            name="radioFrequency"
                                            type="radio"
                                            value="monthly"
                                            checked={radioFrequency === "monthly"}
                                            onChange={onRadioChange}
                                        />
                                        {' '}
                                        <Label check>
                                            Monthly
                                        </Label>
                                    </FormGroup>
                                    {objFormErrors.radioFrequency ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.radioFrequency}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.endDateTime && "text-danger"}`}>
                                    <Label >End Date</Label>
                                    <DatePicker
                                        selected={endDateTime}
                                        onChange={(date: any) => setEndDateTime(date)}
                                        dateFormat="MMMM d, yyyy"
                                        timeIntervals={60}
                                        className={`form-control ${objFormErrors.endDateTime &&
                                            "is-invalid"}`}
                                        minDate={new Date()}
                                    // minTime={calculateMinTime()} //set current time to disable past time
                                    // maxTime={setHours(setMinutes(new Date(), 59), 23)} //this set the max time (i.e. 23:59)
                                    />
                                    {objFormErrors.endDateTime ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.endDateTime}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>

                    </CardBody>
                    <CardFooterRight>

                        {
                            isSavingScheduleAutomation === false ?
                                <Button
                                    type='button'
                                    icon='Save'
                                    color='success'
                                    isOutline
                                    onClick={() => btnOnClickSaveScheduleAutomation()}
                                >
                                    Save
                                </Button>
                                : <LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' />
                        }
                        <Button
                            type='button'
                            icon='Cancel'
                            color='danger'
                            isOutline
                            // isDisable={!formik.isValid && !!formik.submitCount}
                            onClick={props.toggle}
                        >
                            Cancel
                        </Button>
                    </CardFooterRight>
                </Card>



            </div>
        </Fragment>
    )
}
export default ScheduleAutomationModalBody
