import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSheetWorkSpaces,
  fetchWorkSpaceByWorkSpaceId
} from "./workSpaceActions";
// import type { RootState } from '../../features/storeConfig/store'
import type { PayloadAction } from '@reduxjs/toolkit'


// import { getUserDetails, registerUser,  } from "./userActions";

// initialize userToken from local storage
// const userToken = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;
type InitialStateType = {
  objWorkSpacesData?: any;
  objWorkSpaceDataByWorkSpaceId?: any;
  loading?: boolean;
}
const initialState : InitialStateType = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
  
};

const workSpaceSlice : any = createSlice({
  name: "workSpace",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: (builder) => {
    builder
    // fetchSheetWorkSpaces
    .addCase(fetchSheetWorkSpaces.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchSheetWorkSpaces.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.objWorkSpacesData = action.payload;
    })
    .addCase(fetchSheetWorkSpaces.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.objWorkSpacesData = action.payload;
    })
    // fetchWorkSpaceByWorkSpaceId
    .addCase(fetchWorkSpaceByWorkSpaceId.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchWorkSpaceByWorkSpaceId.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.objWorkSpaceDataByWorkSpaceId = action.payload;
    })
    .addCase(fetchWorkSpaceByWorkSpaceId.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.objWorkSpaceDataByWorkSpaceId = action.payload;
    })
    
  },
});

export default workSpaceSlice.reducer;
