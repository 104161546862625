import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Calendar, dayjsLocalizer, View as TView, Views } from 'react-big-calendar';
import classNames from 'classnames';
import dayjs from 'dayjs';
// import { Calendar as DatePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
// import SubHeader, {
// 	SubHeaderLeft,
// 	SubHeaderRight,
// 	SubheaderSeparator,
// } from '../../../layout/SubHeader/SubHeader';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
// import Avatar from '../../../components/Avatar';
// import User1Webp from '../../../assets/img/wanna/wanna2.webp';
// import User1Img from '../../../assets/img/wanna/wanna2.png';
// import Input from '../../../components/bootstrap/forms/Input';
// import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import CommonDesc from '../../../common/other/CommonDesc';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useAppDispatch, useAppSelector } from '../../../features/storeConfig/store';
import { unwrapResult } from '@reduxjs/toolkit';

import "../../../styles/pages/AutomationHistoryPage.scss";

// import { demoPagesMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
// import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import ScheduleAutomationModal from './components/ScheduleAutomationModal/ScheduleAutomationModal';
import {
	checkAllCheckBoxDesignAutomationsHistory,
	clearDesignAutomationHistory, fetchDesignAutomationsHistory,
	saveDesignAutomations,
	toggleCheckBoxDesignAutomationsHistory
} from '../../../features/designAutomations/designAutomationsActions';
import LoadAutomationsHistoryList from './components/LoadAutomationsHistoryList';
import LoadingSpninner from '../../../utils/loading/LoadingSpninner';
import showNotification from '../../../components/extras/showNotification';
import { getUniqueId } from '../../../helpers/general';
const SKIP_AH = 0;
const LIMIT_AH = 10;
const AutomationHistory = () => {
	const { darkModeStatus } = useDarkMode();

	const navigate = useNavigate();

	const [skip, setSkip] = useState(SKIP_AH);
	const [limit, setLimit] = useState(LIMIT_AH);
	const [isLoadingAutomationHistory, setIsLoadingAutomationHistory] = useState<boolean>(false);
	const [isShowScheduleAutomationModal, setIsShowScheduleAutomationModal] = useState<boolean>(false);
	const [isRunningAutomations, setIsRunningAutomations] = useState<boolean>(false);

	const [total_design_automations, setTotalDesignAutomations] = useState(0);
	const btnOnClickScheduleAutomation = () => {
		const objSeletedData = [];
		for (let index = 0; index < objDesignAutomations.objDesignAutomations.objDesignAutomations.length; index++) {
			for (let indexAutomationList = 0; indexAutomationList < objDesignAutomations.objDesignAutomations.objDesignAutomations[index].objAutomationsList.length; indexAutomationList++) {
				const elementSub = objDesignAutomations.objDesignAutomations.objDesignAutomations[index].objAutomationsList[indexAutomationList];
				if (elementSub.is_checked) {
					objSeletedData.push(elementSub);
				}
			}
		}
		console.log('objSeletedData in btnOnClickScheduleAutomation')
		console.log(objSeletedData)
		if (objSeletedData.length > 0) {
			setIsShowScheduleAutomationModal(true);
		} else {
			showNotification("Please select some rows in order schedule an automation.", '', 'danger');
		}
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		// console.log("id in useeffect");
		// console.log("id in useeffect");
		// console.log("id in useeffect");
		// console.log(id);
		loadMoreAutomationHistory(skip, limit);

		return () => {
			dispatch(clearDesignAutomationHistory());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const loadMoreAutomationHistory = (_skip: number, _limit: number) => {
		setIsLoadingAutomationHistory(true);
		dispatch(fetchDesignAutomationsHistory({ skip: _skip, limit: _limit }))
			.then(unwrapResult)
			.then((originalPromiseResult) => {
				setIsLoadingAutomationHistory(false);
				// handle result here
				const response = originalPromiseResult;
				console.log("response in fetchDesignAutomationsHistory");
				console.log(response);
				setTotalDesignAutomations(response.total_design_automations)
			}).catch((rejectedValueOrSerializedError) => {
				setIsLoadingAutomationHistory(false);
				console.log("rejectedValueOrSerializedError in fetchDesignAutomationsHistory");
				console.log(rejectedValueOrSerializedError);
			});
	};

	const btnOnClickLoadMoreAutomationHistory = () => {
		let updSkip: number = skip + limit;
		// setSkip(skip => skip + limit);
		setSkip(updSkip);
		loadMoreAutomationHistory(updSkip, limit);
	};

	const handleOnClickSelectCheckBox = (event: any, objAutomation: any) => {
		console.log('event in handleOnClickSelectCheckBox');
		console.log('event in handleOnClickSelectCheckBox');
		console.log(event);
		console.log('event.target in handleOnClickSelectCheckBox');
		console.log('event.target in handleOnClickSelectCheckBox');
		console.log(event.target);
		console.log('objAutomation in handleOnClickSelectCheckBox');
		console.log('objAutomation in handleOnClickSelectCheckBox');
		console.log(objAutomation);
		const objToggleData = {
			automation_design_id: objAutomation.automation_design_id,
			id: objAutomation.id,
		}
		dispatch(toggleCheckBoxDesignAutomationsHistory(objToggleData))
	}

	const btnOnClickCheckAll = () => {
		dispatch(checkAllCheckBoxDesignAutomationsHistory())
	}
	const btnOnClickRunAutomation = () => {

		console.log('objDesignAutomations.objDesignAutomations in btnOnClickRunAutomation');
		console.log(objDesignAutomations.objDesignAutomations);
		console.log('objDesignAutomations.objDesignAutomations.objDesignAutomations in btnOnClickRunAutomation');
		console.log(objDesignAutomations.objDesignAutomations.objDesignAutomations);

		let objSeletedData = [];
		for (let index = 0; index < objDesignAutomations.objDesignAutomations.objDesignAutomations.length; index++) {
			for (let indexAutomationList = 0; indexAutomationList < objDesignAutomations.objDesignAutomations.objDesignAutomations[index].objAutomationsList.length; indexAutomationList++) {
				let elementSub = objDesignAutomations.objDesignAutomations.objDesignAutomations[index].objAutomationsList[indexAutomationList];
				if (elementSub.is_checked) {
					objSeletedData.push(elementSub);
				}
			}
		}
		console.log('objSeletedData')
		console.log(objSeletedData)
		if (objSeletedData.length > 0) {
			// let objNewSeletedData = [...objSeletedData];
			let objNewSeletedData = JSON.parse(JSON.stringify(objSeletedData));
			console.log('objNewSeletedData')
			console.log('objNewSeletedData')
			console.log('objNewSeletedData')
			console.log(objNewSeletedData)
			for (let index = 0; index < objNewSeletedData.length; index++) {
				let elementSeletedData = objNewSeletedData[index];
				console.log('elementSeletedData')
				console.log('elementSeletedData')
				console.log('elementSeletedData')
				console.log(elementSeletedData)
				objNewSeletedData[index].id = getUniqueId();
			}
			let objPostData = { objAutomationsList: objNewSeletedData }
			setIsRunningAutomations(true);
			dispatch(saveDesignAutomations(objPostData))
				.then(unwrapResult)
				.then((originalPromiseResult: any) => {
					setIsRunningAutomations(false);
					console.log("originalPromiseResult in saveDesignAutomations");
					console.log(originalPromiseResult);
					const response = originalPromiseResult;
					console.log("response.data in saveDesignAutomations");
					console.log(response.data);
					if (response.data.error === false) {
						showNotification(response.data.msg, '', 'success');
						setTimeout(() =>{
                            window.location.reload(); // you can pass true to reload function to ignore the client cache and reload from the server
                        },3000); 
					} else {
						showNotification(response.data.msg, '', 'danger')
					}
				})
				.catch((rejectedValueOrSerializedError: any) => {
					setIsRunningAutomations(false);
					showNotification("Ops something went wrong please try again", '', 'danger')
					console.log("rejectedValueOrSerializedError");
					console.log(rejectedValueOrSerializedError);
				});
		} else {
			showNotification("Please select some rows in order to run them.", '', 'danger');
		}
	}



	const { objDesignAutomations }: any = useAppSelector(state => ({
		objDesignAutomations: state.designAutomations.designAutomations,
	}));
	console.log('objDesignAutomations in render')
	console.log('objDesignAutomations in render')
	console.log('objDesignAutomations in render')
	console.log(objDesignAutomations)
	console.log('objDesignAutomations.objDesignAutomations in render')
	console.log('objDesignAutomations.objDesignAutomations in render')
	console.log('objDesignAutomations.objDesignAutomations in render')
	console.log(objDesignAutomations.objDesignAutomations)
	console.log('objDesignAutomations.objDesignAutomations.total_design_automations in render')
	console.log('objDesignAutomations.objDesignAutomations.total_design_automations in render')
	console.log('objDesignAutomations.objDesignAutomations.total_design_automations in render')
	console.log(objDesignAutomations.objDesignAutomations.total_design_automations)
	console.log('objDesignAutomations.objDesignAutomations.total_design_automations in render')
	console.log('objDesignAutomations.objDesignAutomations.total_design_automations in render')
	console.log('objDesignAutomations.objDesignAutomations.total_design_automations in render')
	console.log(objDesignAutomations.objDesignAutomations.total_design_automations)
	console.log('objDesignAutomations.objDesignAutomations.objDesignAutomations.length in render')
	console.log('objDesignAutomations.objDesignAutomations.objDesignAutomations.length in render')
	console.log('objDesignAutomations.objDesignAutomations.objDesignAutomations.length in render')
	console.log(objDesignAutomations.objDesignAutomations.objDesignAutomations.length)
	


	return (
		<PageWrapper
			title={"Automation History"}
			isProtected={false}
			page_auth_type={'dashboard_page'}
			checkAuth={true}
			isRedirect={true}
		>
			<Page container='fluid'>
				<div className='row h-100'>

					<div className='col-xxl-12 col-xl-12 col-lg-12'>
						<Card stretch={true}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Alarm' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Your List
									</CardTitle>
								</CardLabel>
								<CardActions>

									<Button
										color='info'
										icon='Schedule'
										isLight
										onClick={() => btnOnClickScheduleAutomation()}
									>
										Schedule Automation
									</Button>
									<Button
										color='success'
										icon='Send'
										isLight
										onClick={() => btnOnClickRunAutomation()}
									>
										Run Automation
									</Button>
									<Button
										color='primary'
										icon='DoneAll'
										isLight
										onClick={() => btnOnClickCheckAll()}
									>
										Select All Automations
									</Button>
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive' >
								<table className='table table-modern AutomationHistoryTable'>
									<thead>
										<tr>
											<th>Select</th>
											<th>Sync Message</th>
											<th>Dest Workspace Name</th>
											<th>Dest Folder Name</th>
											<th>Dest Sheet Name</th>
											<th>Dest Column Name</th>
											<th>Src Workspace Name</th>
											<th>Src Folder Name</th>
											<th>Src Sheet Name</th>
											<th>Src Column Name</th>
											<th>Sync Date Time</th>
										</tr>
									</thead>
									{
										objDesignAutomations !== undefined && (Object.keys(objDesignAutomations).length > 0) ?
											<tbody>
												<LoadAutomationsHistoryList
													objDesignAutomations={objDesignAutomations}
													darkModeStatus={darkModeStatus}
													handleOnClickSelectCheckBox={handleOnClickSelectCheckBox}
												/>
											</tbody>
											: null
									}
								</table>
								{
									isLoadingAutomationHistory ? <LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' /> : null
								}
								{
									isLoadingAutomationHistory === false && objDesignAutomations.objDesignAutomations !== undefined && objDesignAutomations.objDesignAutomations.total_design_automations !== 0 && objDesignAutomations.objDesignAutomations.total_design_automations > objDesignAutomations.objDesignAutomations.objDesignAutomations.length ?
										<div className='text-center'>

											<Button
												color='info'
												icon='Schedule'
												isLight
												onClick={() => btnOnClickLoadMoreAutomationHistory()}
											>
												Load more
											</Button>
										</div>
										: null
								}
							</CardBody>

						</Card>
					</div>
				</div>
				<ScheduleAutomationModal
					objDesignAutomations={objDesignAutomations}
					isShowScheduleAutomationModal={isShowScheduleAutomationModal}
					setIsShowScheduleAutomationModal={setIsShowScheduleAutomationModal}
				/>,
			</Page>
		</PageWrapper>
	);
};

export default AutomationHistory;
