import React, { FC, Fragment } from "react"
import classNames from "classnames";
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { formatDate } from "../../../../helpers/Date/DateTimeHelpers";
import Button from "../../../../components/bootstrap/Button";
interface IScheduledAutomationsListTrProps {
    automation_job_name?: any;
    start_datetime?: any;
    end_datetime?: any;
    frequency?: any;
    createdAt?: any;
    // _id: any;
    handleOnClickDeleteScheduledAutomation: any;
    darkModeStatus?: any;
}
const ScheduledAutomationsListTr: FC<IScheduledAutomationsListTrProps> = (props) => {
    
    const {automation_job_name,
        start_datetime,end_datetime,frequency,createdAt,
        darkModeStatus, handleOnClickDeleteScheduledAutomation
    }= props;
    // console.log('props in ScheduledAutomationsListTr');
    // console.log('props in ScheduledAutomationsListTr');
    // console.log('props in ScheduledAutomationsListTr');
    // console.log(props)
    
    
    
    return (
        <Fragment>
            <tr >
                
                <td>{automation_job_name}</td>
                <td>{formatDate(start_datetime)}</td>
                <td>{formatDate(end_datetime)}</td>
                <td>{frequency}</td>
                
                
                
                
                <td>{formatDate(createdAt)}</td>
                <td>
                    <Button
                        isOutline={!darkModeStatus}
                        color='danger'
                        isLight={darkModeStatus}
                        className={classNames('text-nowrap', {
                            'border-light': !darkModeStatus,
                        })}
                        icon='Delete'
                        onClick={() => handleOnClickDeleteScheduledAutomation(props)}
                    >
                        Delete
                    </Button>
                </td>

                    
            </tr>
        </Fragment>
        
    )
}
export default ScheduledAutomationsListTr
