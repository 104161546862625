import { combineReducers } from "redux";
// import authReducers from "./auth/";
import smartSheetOAuthSlice from "./smartSheetOAuthSlice";
// import userSignUpFunnelSlice from "./userSignUpFunnelSlice";
// stockPrices/
const smartSheetOAuthReducers = combineReducers({
  smartSheetOAuth: smartSheetOAuthSlice,
  // userSignUpFunnelSlice,
});

export default smartSheetOAuthReducers;
