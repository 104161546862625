import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Icon from '../../../components/icon/Icon';




import useDarkMode from '../../../hooks/useDarkMode';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../features/storeConfig/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchSheetWorkSpaces, fetchWorkSpaceByWorkSpaceId } from '../../../features/smartSheet/workSpaces/workSpaceActions';
import {
	fetchWorkSpaceFoldersByWorkSpaceId,
	fetchWorkSpaceFolderSheetsByFolderId,
	fetchWorkSpaceFolderSheetColumnsBySheetId
} from '../../../features/smartSheet/workSpaceFolders/workSpaceFoldersActions';
import AuthContext from '../../../contexts/authContext';
import showNotification from '../../../components/extras/showNotification';
import SourceAutomationSection from './components/SourceAutomationSection/SourceAutomationSection';
import DestinationAutomationSection from './components/DestinationAutomationSection/DestinationAutomationSection';
import AutomationsListSection from './components/AutomationsListSection/AutomationsListSection';
import { getUniqueId } from '../../../helpers/general';
import { saveDesignAutomations } from '../../../features/designAutomations/designAutomationsActions';
import { buildFolderTree } from '../../../helpers/Folder/DynamicFolderHelpers';


const DataTransfer = () => {
	const { darkModeStatus } = useDarkMode();

	const navigate = useNavigate();
	const { objUser } = useContext(AuthContext);
	const [isLoadingAllWorkSpaces, setIsLoadingAllWorkSpaces] = useState<boolean>(false);
	const [isLoadingSourceWorkSpaceFoldersByWorkSpaceId, setIsLoadingSourceWorkSpaceFoldersByWorkSpaceId] = useState<boolean>(false);
	const [isLoadingSourceWorkSpaceFolderSheetsByFolderId, setIsLoadingSourceWorkSpaceFolderSheetsByFolderId] = useState<boolean>(false);
	const [isLoadingSourceWorkSpaceFolderSheetColumnsBySheetId, setIsLoadingSourceWorkSpaceFolderSheetColumnsBySheetId] = useState<boolean>(false);
	const [isLoadingSourceWorkSpaceByWorkSpaceId, setIsLoadingSourceWorkSpaceByWorkSpaceId] = useState<boolean>(false);


	const [isLoadingDestinationWorkSpaceFoldersByWorkSpaceId, setIsLoadingDestinationWorkSpaceFoldersByWorkSpaceId] = useState<boolean>(false);
	const [isLoadingDestinationWorkSpaceFolderSheetsByFolderId, setIsLoadingDestinationWorkSpaceFolderSheetsByFolderId] = useState<boolean>(false);
	const [isLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId, setIsLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId] = useState<boolean>(false);
	const [isLoadingDestinationWorkSpaceByWorkSpaceId, setIsLoadingDestinationWorkSpaceByWorkSpaceId] = useState<boolean>(false);


	const [formattedWorkSpacesArr, setFormattedWorkSpacesArr] = useState([]);
	const [objSelectedSourceWorkSpace, setObjSelectedSourceWorkSpace] = useState({});
	const [objSelectedDestinationWorkSpace, setObjSelectedDestinationWorkSpace] = useState({});

	const [formattedSourceFoldersArr, setFormattedSourceFoldersArr] = useState([]);
	const [objSelectedSourceFolder, setObjSelectedSourceFolder] = useState({});
	const [formattedSourceSheetsArr, setFormattedSourceSheetsArr] = useState([]);
	const [objSelectedSourceSheet, setObjSelectedSourceSheet] = useState({});
	const [formattedSourceColumnsArr, setFormattedSourceColumnsArr] = useState([]);
	const [objSelectedSourceColumn, setObjSelectedSourceColumn] = useState({});

	const [formattedDestinationFoldersArr, setFormattedDestinationFoldersArr] = useState([]);
	const [objSelectedDestinationFolder, setObjSelectedDestinationFolder] = useState({});
	const [formattedDestinationSheetsArr, setFormattedDestinationSheetsArr] = useState([]);
	const [objSelectedDestinationSheet, setObjSelectedDestinationSheet] = useState({});
	const [formattedDestinationColumnsArr, setFormattedDestinationColumnsArr] = useState([]);
	const [objSelectedDestinationColumn, setObjSelectedDestinationColumn] = useState({});

	const [objFormErrors, setObjFormErrors] = useState({});

	const [isRunningAutomations, setIsRunningAutomations] = useState<boolean>(false);
	const [objAutomationsList, setObjAutomationsList] = useState<any>([]);

	const dispatch = useAppDispatch();
	// const navigate = useNavigate();
	useEffect(() => {
		if (objUser !== undefined && Object.keys(objUser).length > 0) {
			if (objUser.smartsheet_token_data !== undefined && Object.keys(objUser.smartsheet_token_data).length > 0) {

			} else {
				showNotification("Please connect your smartsheet account first.", '', 'danger')
				let redirectUrl = '/';
				// alert('else isError')
				navigate(redirectUrl);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objUser]);
	useEffect(() => {
		// console.log("id in useeffect");
		// console.log("id in useeffect");
		// console.log("id in useeffect");
		// console.log(id);
		fetchAllSmartSheetWorkSpaces();

		return () => {
			// dispatch(clearUserSmartSheetWorkSpaces());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchAllSmartSheetWorkSpaces = () => {
		setIsLoadingAllWorkSpaces(true);
		dispatch(fetchSheetWorkSpaces())
			.then(unwrapResult)
			.then((originalPromiseResult) => {
				setIsLoadingAllWorkSpaces(false);
				// handle result here
				const response = originalPromiseResult;
				console.log("response in fetchSheetWorkSpaces");
				console.log(response);
				setFormattedWorkSpacesArr(response.data.formattedWorkSpacesArr);
			}).catch((rejectedValueOrSerializedError) => {
				setIsLoadingAllWorkSpaces(false);
				console.log("rejectedValueOrSerializedError in fetchSheetWorkSpaces");
				console.log(rejectedValueOrSerializedError);
			});
	};
	// const buildFolderNode = (item:any) => {
	// 	const node : any = {
	// 		id: item.id,
	// 		name: item.name,
	// 		permalink: item.permalink,
	// 		value: item.id, label: item.name, children:[]
	// 	};
		
	// 	if (item.folders) {
	// 		node.children = item.folders.map(buildFolderNode);
	// 		// node.folders = item.folders.map(buildFolderNode);

	// 	}

	// 	if (item.sheets) {
	// 		node.sheets = item.sheets.map((sheet:any) => ({
	// 			id: sheet.id,
	// 			name: sheet.name,
	// 			permalink: sheet.permalink,
	// 			value: sheet.id, label: sheet.name,
	// 		}));
	// 		for (let index = 0; index < node.sheets.length; index++) {
	// 			const element = node.sheets[index];
	// 			node.children.push(element);
	// 		}
	// 	}

	// 	return node;
	// }
	// const buildFolderTree = (data:any) => {
	// 	const folderTree = [];
	
		
	
	// 	for (const item of data) {
	// 		folderTree.push(buildFolderNode(item));
	// 	}
	
	// 	return folderTree;
	// }
	const fetchWorkSpaceDataByWorkSpaceIdHandler = (_work_space_id: any, call_type: string) => {
		setObjFormErrors({});
		call_type === 'source' ? setIsLoadingSourceWorkSpaceByWorkSpaceId(true) : setIsLoadingDestinationWorkSpaceByWorkSpaceId(true);
		dispatch(fetchWorkSpaceByWorkSpaceId(_work_space_id))
			.then(unwrapResult)
			.then((originalPromiseResult) => {
				call_type === 'source' ? setIsLoadingSourceWorkSpaceByWorkSpaceId(false) : setIsLoadingDestinationWorkSpaceByWorkSpaceId(false);
				// handle result here
				const response = originalPromiseResult;

				console.log("formattedWorkSpacesArr in fetchWorkSpaceDataByWorkSpaceIdHandler");
				console.log(formattedWorkSpacesArr);
				console.log("response in fetchWorkSpaceDataByWorkSpaceIdHandler");
				console.log(response);
				console.log("response.data in fetchWorkSpaceDataByWorkSpaceIdHandler");
				console.log(response.data);
				console.log("response.data.objWorkSpace in fetchWorkSpaceDataByWorkSpaceIdHandler");
				console.log(response.data.objWorkSpace);
				if(response.data.error === false){
					const _objWorkSpace = response.data.objWorkSpace;
					
					setFormattedWorkSpacesArr((oldArray:any) => {
	
						let newObjWorkSpaces : any = [...oldArray];
						newObjWorkSpaces = structuredClone(newObjWorkSpaces)
						console.log('newObjWorkSpaces in setFormattedWorkSpacesArr');
						console.log('newObjWorkSpaces in setFormattedWorkSpacesArr');
						console.log(newObjWorkSpaces);
						const workSpaceIndex : any = newObjWorkSpaces.findIndex((el: any) => el.id.toString() === _work_space_id.toString());
						console.log('workSpaceIndex in setFormattedWorkSpacesArr');
						console.log('workSpaceIndex in setFormattedWorkSpacesArr');
						console.log(workSpaceIndex);
						console.log('newObjWorkSpaces[workSpaceIndex] in setFormattedWorkSpacesArr');
						console.log('newObjWorkSpaces[workSpaceIndex] in setFormattedWorkSpacesArr');
						console.log(newObjWorkSpaces[workSpaceIndex]);
						if(_objWorkSpace.folders){
							const objFoldersData : any = buildFolderTree(_objWorkSpace.folders,_objWorkSpace);
							console.log("objFoldersData")
							console.log("objFoldersData")
							console.log(objFoldersData)
							newObjWorkSpaces[workSpaceIndex].children = objFoldersData;
						}else{
							newObjWorkSpaces[workSpaceIndex].children = [];
						}
						// if(_objWorkSpace.folders.length > 0){
						// 	// let formattedWorkSpacesFoldersArr = [];
						// 	for(let objWorkSpaceFolder of _objWorkSpace.folders) {
						// 		const obj = {value: objWorkSpaceFolder.id, label: objWorkSpaceFolder.name,name:objWorkSpaceFolder.name, id: objWorkSpaceFolder.id, children:[]}
						// 		// formattedWorkSpacesFoldersArr.push(obj);
						// 		newObjWorkSpaces[workSpaceIndex].children.push(obj);
						// 	}
						// }
						if(_objWorkSpace.sheets){
							for(let objFolderSheet of _objWorkSpace.sheets) {
								const obj = {value: objFolderSheet.id, label: objFolderSheet.name,name:objFolderSheet.name, id: objFolderSheet.id}
								// formattedSheetsArr.push(obj);
								newObjWorkSpaces[workSpaceIndex].children.push(obj);
							}
						}
	
						
						
						// newObjWorkSpaces[workSpaceIndex].children=[
						// 	{
						// 		value: 'Folder-1',
						// 		label: 'Folder-1',
						// 		children: [
						// 			{
						// 				value: 'persian',
						// 				label: 'First Persian Empire',
						// 				children:[
						// 					{
						// 						value: 'eren',
						// 						label: 'First eren Empire',
						// 						children:[]
						// 					}
						// 				]
						// 			},
						// 			{
						// 				value: 'qin',
						// 				label: 'Qin Dynasty',
						// 			},
						// 			{
						// 				value: 'spqr',
						// 				label: 'Roman Empire',
						// 			},
						// 		],
						// 	},
						// 	{
						// 		value: 'medieval-era',
						// 		label: 'Medieval Era',
						// 		children: [
						// 			{
						// 				value: 'abbasid',
						// 				label: 'Abbasid Caliphate',
						// 			},
						// 			{
						// 				value: 'byzantine',
						// 				label: 'Byzantine Empire',
						// 			},
						// 			{
						// 				value: 'holy-roman',
						// 				label: 'Holy Roman Empire',
						// 			},
						// 			{
						// 				value: 'ming',
						// 				label: 'Ming Dynasty',
						// 			},
						// 			{
						// 				value: 'mongol',
						// 				label: 'Mongol Empire',
						// 			},
						// 		],
						// 	},
						// 	{
						// 		value: 'modern-era',
						// 		label: 'Modern Era',
						// 		children: [
						// 			{
						// 				value: 'aztec',
						// 				label: 'Aztec Empire',
						// 			},
						// 			{
						// 				value: 'british',
						// 				label: 'British Empire',
						// 			},
						// 			{
						// 				value: 'inca',
						// 				label: 'Inca Empire',
						// 			},
						// 			{
						// 				value: 'qing',
						// 				label: 'Qing Dynasty',
						// 			},
						// 			{
						// 				value: 'russian',
						// 				label: 'Russian Empire',
						// 			},
						// 			{
						// 				value: 'spanish',
						// 				label: 'Spanish Empire',
						// 			},
						// 		],
						// 	},
						// 	{
						// 		value: 'Simple-sheet',
						// 		label: 'Simple-sheet',
								
						// 	},
						// ]
						return newObjWorkSpaces;
	
					});
					if (call_type === 'source') {
						setFormattedSourceFoldersArr(response.data.formattedFoldersArr);
						setFormattedSourceSheetsArr(response.data.formattedSheetsArr);
					} else {
						setFormattedDestinationFoldersArr(response.data.formattedFoldersArr);
						setFormattedDestinationSheetsArr(response.data.formattedSheetsArr);
					}
				}else{
					showNotification("Error in fetching the workspace data, Please try again.",'','danger');
				}

				// setTotalUserWorkSpaces(response.data.total_work_spaces);
			}).catch((rejectedValueOrSerializedError) => {
				console.log('rejectedValueOrSerializedError in fetchWorkSpaceFoldersByWorkSpaceId');
				console.log(rejectedValueOrSerializedError);
				call_type === 'source' ? setIsLoadingSourceWorkSpaceByWorkSpaceId(false) : setIsLoadingDestinationWorkSpaceByWorkSpaceId(false);
			});
	};
	const fetchWorkSpaceFoldersByWorkSpaceIdHandler = (_work_space_id: any, call_type: string) => {
		setObjFormErrors({});
		call_type === 'source' ? setIsLoadingSourceWorkSpaceFoldersByWorkSpaceId(true) : setIsLoadingDestinationWorkSpaceFoldersByWorkSpaceId(true);
		fetchWorkSpaceDataByWorkSpaceIdHandler(_work_space_id, call_type);
		// dispatch(fetchWorkSpaceFoldersByWorkSpaceId(_work_space_id))
		// 	.then(unwrapResult)
		// 	.then((originalPromiseResult) => {
		// 		call_type === 'source' ? setIsLoadingSourceWorkSpaceFoldersByWorkSpaceId(false) : setIsLoadingDestinationWorkSpaceFoldersByWorkSpaceId(false);
		// 		// handle result here
		// 		const response = originalPromiseResult;
		// 		console.log("response in getContacts");
		// 		console.log(response);
		// 		if (call_type === 'source') {
		// 			setFormattedSourceFoldersArr(response.data.formattedWorkSpacesFoldersArr);
		// 		} else {
		// 			setFormattedDestinationFoldersArr(response.data.formattedWorkSpacesFoldersArr);
		// 		}
		// 		if (response.data.objWorkSpacesFoldersList.totalCount === 0) {
		// 			showNotification("This workspace does not have any folders, so we are loading the sheets of this workspace.", '', 'info');
		// 			fetchWorkSpaceDataByWorkSpaceIdHandler(_work_space_id, call_type);
		// 		}

		// 		// setTotalUserWorkSpaces(response.data.total_work_spaces);
		// 	}).catch((rejectedValueOrSerializedError) => {
		// 		console.log('rejectedValueOrSerializedError in fetchWorkSpaceFoldersByWorkSpaceId');
		// 		console.log(rejectedValueOrSerializedError);
		// 		call_type === 'source' ? setIsLoadingSourceWorkSpaceFoldersByWorkSpaceId(false) : setIsLoadingDestinationWorkSpaceFoldersByWorkSpaceId(false);
		// 	});
	};


	const fetchWorkSpaceFolderSheetsByFolderIdHandler = (_folder_id: any, call_type: string, treeDepth: any, workspace_id: any) => {
		setObjFormErrors({});
		call_type === 'source' ? setIsLoadingSourceWorkSpaceFolderSheetsByFolderId(true) : setIsLoadingDestinationWorkSpaceFolderSheetsByFolderId(true);
		dispatch(fetchWorkSpaceFolderSheetsByFolderId(_folder_id))
			.then(unwrapResult)
			.then((originalPromiseResult) => {
				call_type === 'source' ? setIsLoadingSourceWorkSpaceFolderSheetsByFolderId(false) : setIsLoadingDestinationWorkSpaceFolderSheetsByFolderId(false);
				// handle result here
				const response = originalPromiseResult;
				console.log("response in fetchWorkSpaceFolderSheetsByFolderIdHandler");
				console.log(response);
				console.log("response.data in fetchWorkSpaceFolderSheetsByFolderIdHandler");
				console.log(response.data);
				console.log("response.data.objFolder in fetchWorkSpaceFolderSheetsByFolderIdHandler");
				console.log(response.data.objFolder);
				const _objFolder = response.data.objFolder;
				
				setFormattedWorkSpacesArr((oldArray:any) => {

					let newObjWorkSpaces : any = [...oldArray];
					newObjWorkSpaces = structuredClone(newObjWorkSpaces)
					console.log('newObjWorkSpaces in setFormattedWorkSpacesArr');
					console.log('newObjWorkSpaces in setFormattedWorkSpacesArr');
					console.log(newObjWorkSpaces);
					const workSpaceIndex : any = newObjWorkSpaces.findIndex((el: any) => el.id.toString() === workspace_id.toString());
					console.log('workSpaceIndex in setFormattedWorkSpacesArr');
					console.log('workSpaceIndex in setFormattedWorkSpacesArr');
					console.log(workSpaceIndex);
					console.log('newObjWorkSpaces[workSpaceIndex] in setFormattedWorkSpacesArr');
					console.log('newObjWorkSpaces[workSpaceIndex] in setFormattedWorkSpacesArr');
					console.log(newObjWorkSpaces[workSpaceIndex]);
					
					// if(_objFolder.folders.length > 0){
					// 	// let formattedWorkSpacesFoldersArr = [];
					// 	for(let objWorkSpaceFolder of _objFolder.folders) {
					// 		const obj = {value: objWorkSpaceFolder.id, label: objWorkSpaceFolder.name,name:objWorkSpaceFolder.name, id: objWorkSpaceFolder.id, children:[]}
					// 		// formattedWorkSpacesFoldersArr.push(obj);
					// 		newObjWorkSpaces[workSpaceIndex].children.push(obj);
					// 	}
					// }
					// if(_objFolder.sheets){
					// 	for(let objFolderSheet of _objFolder.sheets) {
					// 		const obj = {value: objFolderSheet.id, label: objFolderSheet.name,name:objFolderSheet.name, id: objFolderSheet.id}
					// 		// formattedSheetsArr.push(obj);
					// 		newObjWorkSpaces[workSpaceIndex].children.push(obj);
					// 	}
					// }
					
					// newObjWorkSpaces[workSpaceIndex].children=[
					// 	{
					// 		value: 'Folder-1',
					// 		label: 'Folder-1',
					// 		children: [
					// 			{
					// 				value: 'persian',
					// 				label: 'First Persian Empire',
					// 				children:[
					// 					{
					// 						value: 'eren',
					// 						label: 'First eren Empire',
					// 						children:[]
					// 					}
					// 				]
					// 			},
					// 			{
					// 				value: 'qin',
					// 				label: 'Qin Dynasty',
					// 			},
					// 			{
					// 				value: 'spqr',
					// 				label: 'Roman Empire',
					// 			},
					// 		],
					// 	},
					// 	{
					// 		value: 'medieval-era',
					// 		label: 'Medieval Era',
					// 		children: [
					// 			{
					// 				value: 'abbasid',
					// 				label: 'Abbasid Caliphate',
					// 			},
					// 			{
					// 				value: 'byzantine',
					// 				label: 'Byzantine Empire',
					// 			},
					// 			{
					// 				value: 'holy-roman',
					// 				label: 'Holy Roman Empire',
					// 			},
					// 			{
					// 				value: 'ming',
					// 				label: 'Ming Dynasty',
					// 			},
					// 			{
					// 				value: 'mongol',
					// 				label: 'Mongol Empire',
					// 			},
					// 		],
					// 	},
					// 	{
					// 		value: 'modern-era',
					// 		label: 'Modern Era',
					// 		children: [
					// 			{
					// 				value: 'aztec',
					// 				label: 'Aztec Empire',
					// 			},
					// 			{
					// 				value: 'british',
					// 				label: 'British Empire',
					// 			},
					// 			{
					// 				value: 'inca',
					// 				label: 'Inca Empire',
					// 			},
					// 			{
					// 				value: 'qing',
					// 				label: 'Qing Dynasty',
					// 			},
					// 			{
					// 				value: 'russian',
					// 				label: 'Russian Empire',
					// 			},
					// 			{
					// 				value: 'spanish',
					// 				label: 'Spanish Empire',
					// 			},
					// 		],
					// 	},
					// 	{
					// 		value: 'Simple-sheet',
					// 		label: 'Simple-sheet',
							
					// 	},
					// ]
					return newObjWorkSpaces;

				});
				// if (call_type === 'source') {
				// 	setFormattedSourceSheetsArr(response.data.formattedSheetsArr);
				// } else {
				// 	setFormattedDestinationSheetsArr(response.data.formattedSheetsArr);
				// }
				// setTotalUserWorkSpaces(response.data.total_work_spaces);
			}).catch((rejectedValueOrSerializedError) => {
				console.log('rejectedValueOrSerializedError in fetchWorkSpaceFoldersByWorkSpaceId');
				console.log(rejectedValueOrSerializedError);
				call_type === 'source' ? setIsLoadingSourceWorkSpaceFolderSheetsByFolderId(false) : setIsLoadingDestinationWorkSpaceFolderSheetsByFolderId(false);
			});
	};

	const fetchWorkSpaceSheetColumnsBySheetIdHandler = (_sheet_id: any, call_type: string) => {
		setObjFormErrors({});
		call_type === 'source' ? setIsLoadingSourceWorkSpaceFolderSheetColumnsBySheetId(true) : setIsLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId(true);
		dispatch(fetchWorkSpaceFolderSheetColumnsBySheetId(_sheet_id))
			.then(unwrapResult)
			.then((originalPromiseResult) => {
				call_type === 'source' ? setIsLoadingSourceWorkSpaceFolderSheetColumnsBySheetId(false) : setIsLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId(false);
				// handle result here
				const response = originalPromiseResult;
				console.log("response in getContacts");
				console.log(response);
				if (call_type === 'source') {
					setFormattedSourceColumnsArr(response.data.formattedColumnsArr);
				} else {
					setFormattedDestinationColumnsArr(response.data.formattedDestinationColumnsArr);
				}
				// setTotalUserWorkSpaces(response.data.total_work_spaces);
			}).catch((rejectedValueOrSerializedError) => {
				console.log('rejectedValueOrSerializedError in fetchWorkSpaceFoldersByWorkSpaceId');
				console.log(rejectedValueOrSerializedError);
				call_type === 'source' ? setIsLoadingSourceWorkSpaceFolderSheetColumnsBySheetId(false) : setIsLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId(false);
			});
	};
	const validateDataTransferForm = () => {
		let error = false;
		const _objFormErrors: any = {};
		// source
		if (Object.keys(objSelectedSourceWorkSpace).length === 0) {
			error = true;
			_objFormErrors['objSelectedSourceWorkSpace'] = 'Please select your workspace.';
		}
		// if(Object.keys(objSelectedSourceFolder).length === 0){
		// 	_objFormErrors['objSelectedSourceFolder'] = 'Please select your workspace.';
		// }
		if (Object.keys(objSelectedSourceSheet).length === 0) {
			error = true;
			_objFormErrors['objSelectedSourceSheet'] = 'Please select your sheet.';
		}
		if (Object.keys(objSelectedSourceColumn).length === 0) {
			error = true;
			_objFormErrors['objSelectedSourceColumn'] = 'Please select your column.';
		}
		// Destination
		if (Object.keys(objSelectedDestinationWorkSpace).length === 0) {
			error = true;
			_objFormErrors['objSelectedDestinationWorkSpace'] = 'Please select your workspace.';
		}
		if (Object.keys(objSelectedDestinationSheet).length === 0) {
			error = true;
			_objFormErrors['objSelectedDestinationSheet'] = 'Please select your sheet.';
		}
		if (Object.keys(objSelectedDestinationColumn).length === 0) {
			error = true;
			_objFormErrors['objSelectedDestinationColumn'] = 'Please select your column.';
		}
		setObjFormErrors(_objFormErrors);
		return error;
	}
	const btnOnClickAddAutomation = () => {
		const isError = validateDataTransferForm();
		if (isError === false) {
			const newObjAutomation: any = {
				id: getUniqueId(),
				objSelectedSourceWorkSpace,
				objSelectedDestinationWorkSpace,

				objSelectedSourceFolder,
				objSelectedSourceSheet,
				objSelectedSourceColumn,

				objSelectedDestinationFolder,
				objSelectedDestinationSheet,
				objSelectedDestinationColumn,

			}

			console.log('newObjAutomation in btnOnClickAddAutomation');
			console.log('newObjAutomation in btnOnClickAddAutomation');
			console.log('newObjAutomation in btnOnClickAddAutomation');
			console.log(newObjAutomation);
			setObjAutomationsList((oldArray: any) => {
				let newObjAutomationList: any = [...oldArray, newObjAutomation];
				return newObjAutomationList;
			});

		} else {
			showNotification("Please select your Workspace, folders, sheets and columns or Workspace, sheets and columns first.", '', 'danger')
		}

	}

	const btnOnClickRunAutomation = () => {
		if (objAutomationsList.length > 0) {
			const objPostData = {
				objAutomationsList,
			};
			setIsRunningAutomations(true);
			dispatch(saveDesignAutomations(objPostData))
				.then(unwrapResult)
				.then((originalPromiseResult: any) => {
					setIsRunningAutomations(false);
					console.log("originalPromiseResult in saveDesignAutomations");
					console.log(originalPromiseResult);
					const response = originalPromiseResult;
					console.log("response.data in saveDesignAutomations");
					console.log(response.data);
					if (response.data.error === false) {
						setObjAutomationsList([]);
						showNotification(response.data.msg, '', 'success')
					} else {
						showNotification(response.data.msg, '', 'danger')
					}
				})
				.catch((rejectedValueOrSerializedError: any) => {
					setIsRunningAutomations(false);
					showNotification("Ops something went wrong please try again", '', 'danger')
					console.log("rejectedValueOrSerializedError");
					console.log(rejectedValueOrSerializedError);
				});
		} else {
			showNotification("Please add some automations first.", '', 'danger')
		}
	}

	const btnOnClickRemoveAutomation = (_objAutomation: any) => {
		console.log('_objAutomation in btnOnClickRemoveAutomation');
		console.log(_objAutomation);
		const conf = window.confirm('Are you sure, You want to remove this automation?');
		if (conf) {
			setObjAutomationsList(objAutomationsList.filter((item: any) => item.id !== _objAutomation.id));
		}
	}
	console.log('objAutomationsList');
	console.log(objAutomationsList);
	return (
		<PageWrapper
			title={"Design Automation"}
			isProtected={false}
			page_auth_type={'dashboard_page'}
			checkAuth={true}
			isRedirect={true}
		>
			<Page container='fluid'>
				<div className='row h-100'>
					<div className='col-xxl-12 col-xl-12 col-lg-12'>
						<Card stretch tag='form' >
							<CardHeader>
								<CardLabel icon='DataExploration' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Data Transfer
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody >
								<div className='row'>
									<SourceAutomationSection
										formattedWorkSpacesArr={formattedWorkSpacesArr}
										setFormattedWorkSpacesArr={setFormattedWorkSpacesArr}
										objSelectedSourceWorkSpace={objSelectedSourceWorkSpace}
										setObjSelectedSourceWorkSpace={setObjSelectedSourceWorkSpace}
										formattedSourceFoldersArr={formattedSourceFoldersArr}
										setFormattedSourceFoldersArr={setFormattedSourceFoldersArr}
										objSelectedSourceFolder={objSelectedSourceFolder}
										setObjSelectedSourceFolder={setObjSelectedSourceFolder}
										formattedSourceSheetsArr={formattedSourceSheetsArr}
										setFormattedSourceSheetsArr={setFormattedSourceSheetsArr}
										objSelectedSourceSheet={objSelectedSourceSheet}
										setObjSelectedSourceSheet={setObjSelectedSourceSheet}
										formattedSourceColumnsArr={formattedSourceColumnsArr}
										setFormattedSourceColumnsArr={setFormattedSourceColumnsArr}
										objSelectedSourceColumn={objSelectedSourceColumn}
										setObjSelectedSourceColumn={setObjSelectedSourceColumn}
										fetchWorkSpaceDataByWorkSpaceIdHandler={fetchWorkSpaceDataByWorkSpaceIdHandler}
										fetchWorkSpaceFoldersByWorkSpaceIdHandler={fetchWorkSpaceFoldersByWorkSpaceIdHandler}
										fetchWorkSpaceFolderSheetsByFolderIdHandler={fetchWorkSpaceFolderSheetsByFolderIdHandler}
										fetchWorkSpaceSheetColumnsBySheetIdHandler={fetchWorkSpaceSheetColumnsBySheetIdHandler}
										isLoadingAllWorkSpaces={isLoadingAllWorkSpaces}
										isLoadingSourceWorkSpaceFoldersByWorkSpaceId={isLoadingSourceWorkSpaceFoldersByWorkSpaceId}
										isLoadingSourceWorkSpaceFolderSheetsByFolderId={isLoadingSourceWorkSpaceFolderSheetsByFolderId}
										isLoadingSourceWorkSpaceFolderSheetColumnsBySheetId={isLoadingSourceWorkSpaceFolderSheetColumnsBySheetId}
										isLoadingSourceWorkSpaceByWorkSpaceId={isLoadingSourceWorkSpaceByWorkSpaceId}
										objFormErrors={objFormErrors}
									/>
									<DestinationAutomationSection
										formattedWorkSpacesArr={formattedWorkSpacesArr}
										setFormattedWorkSpacesArr={setFormattedWorkSpacesArr}
										formattedDestinationFoldersArr={formattedDestinationFoldersArr}
										setFormattedDestinationFoldersArr={setFormattedDestinationFoldersArr}
										objSelectedDestinationWorkSpace={objSelectedDestinationWorkSpace}
										setObjSelectedDestinationWorkSpace={setObjSelectedDestinationWorkSpace}
										objSelectedDestinationFolder={objSelectedDestinationFolder}
										setObjSelectedDestinationFolder={setObjSelectedDestinationFolder}
										formattedDestinationSheetsArr={formattedDestinationSheetsArr}
										setFormattedDestinationSheetsArr={setFormattedDestinationSheetsArr}
										objSelectedDestinationSheet={objSelectedDestinationSheet}
										setObjSelectedDestinationSheet={setObjSelectedDestinationSheet}
										formattedDestinationColumnsArr={formattedDestinationColumnsArr}
										setFormattedDestinationColumnsArr={setFormattedDestinationColumnsArr}
										objSelectedDestinationColumn={objSelectedDestinationColumn}
										setObjSelectedDestinationColumn={setObjSelectedDestinationColumn}
										fetchWorkSpaceDataByWorkSpaceIdHandler={fetchWorkSpaceDataByWorkSpaceIdHandler}
										fetchWorkSpaceFoldersByWorkSpaceIdHandler={fetchWorkSpaceFoldersByWorkSpaceIdHandler}
										fetchWorkSpaceFolderSheetsByFolderIdHandler={fetchWorkSpaceFolderSheetsByFolderIdHandler}
										fetchWorkSpaceSheetColumnsBySheetIdHandler={fetchWorkSpaceSheetColumnsBySheetIdHandler}
										isLoadingAllWorkSpaces={isLoadingAllWorkSpaces}
										isLoadingDestinationWorkSpaceFoldersByWorkSpaceId={isLoadingDestinationWorkSpaceFoldersByWorkSpaceId}
										isLoadingDestinationWorkSpaceFolderSheetsByFolderId={isLoadingDestinationWorkSpaceFolderSheetsByFolderId}
										isLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId={isLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId}
										isLoadingDestinationWorkSpaceByWorkSpaceId={isLoadingDestinationWorkSpaceByWorkSpaceId}
										objFormErrors={objFormErrors}
									/>
								</div>
							</CardBody>
							<CardFooter>
								{/* <CardFooterLeft>
									<Button
										color='info'
										isLink
										type='reset'
										onClick={formik.resetForm}>
										Reset
									</Button>
								</CardFooterLeft> */}
								<CardFooterRight>
									<Button
										type='button'
										icon='add'
										color='info'
										isOutline
										onClick={() => btnOnClickAddAutomation()}
									// isDisable={!formik.isValid && !!formik.submitCount}
									>
										Add Automation
									</Button>
								</CardFooterRight>
							</CardFooter>
						</Card>
					</div>

					<AutomationsListSection
						isRunningAutomations={isRunningAutomations}
						objAutomationsList={objAutomationsList}
						darkModeStatus={darkModeStatus}
						btnOnClickRemoveAutomation={btnOnClickRemoveAutomation}
						btnOnClickRunAutomation={btnOnClickRunAutomation}
					/>

				</div>
			</Page>
		</PageWrapper>
	);
};

export default DataTransfer;
