import classNames from "classnames";
import React, { FC, Fragment } from "react"
import Button from "../../../../../../components/bootstrap/Button";
interface IAutomationsListTrProps {
    objSelectedSourceWorkSpace?: any;
    objSelectedSourceFolder?: any;
    objSelectedSourceSheet?: any;
    objSelectedSourceColumn?: any;
    objSelectedDestinationWorkSpace?: any;
    objSelectedDestinationFolder?: any;
    objSelectedDestinationSheet?: any;
    objSelectedDestinationColumn?: any;
    darkModeStatus: boolean;
    btnOnClickRemoveAutomation?: any;
}
const AutomationsListTr: FC<IAutomationsListTrProps> = (props) => {
    const {
        objSelectedSourceWorkSpace,
        objSelectedSourceFolder,
        objSelectedSourceSheet,
        objSelectedSourceColumn,
        objSelectedDestinationWorkSpace,
        objSelectedDestinationFolder,
        objSelectedDestinationSheet,
        objSelectedDestinationColumn,
        darkModeStatus,
        btnOnClickRemoveAutomation,
    } = props;

    return (
        <Fragment>
            <tr >

                <td>

                    {objSelectedDestinationWorkSpace.label}
                </td>
                <td>
                    {
                        objSelectedDestinationFolder !== undefined ?
                            objSelectedDestinationFolder.label
                        : null
                    }
                </td>
                <td>
                    {objSelectedDestinationSheet.label}
                </td>
                <td>
                    {objSelectedDestinationColumn.label}
                </td>

                <td>
                    <Button
                        isOutline={!darkModeStatus}
                        color='danger'
                        isLight={darkModeStatus}
                        className={classNames('text-nowrap', {
                            'border-light': !darkModeStatus,
                        })}
                        icon='Edit'
                        onClick={() => btnOnClickRemoveAutomation(props)}
                    >
                        Delete
                    </Button>
                </td>
            </tr>
        </Fragment>
    )
}
export default AutomationsListTr
