import React, { useLayoutEffect, useState, useContext, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Spinner from '../../../components/bootstrap/Spinner';
import AuthContext from '../../../contexts/authContext';
import { useAppDispatch } from '../../../features/storeConfig/store';
import { logout } from '../../../features/user/userActions';
import { unwrapResult } from "@reduxjs/toolkit";

const DashboardHeader = () => {
	const { objUser } = useContext(AuthContext);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then(() =>
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
				</span>,
				'You updated the language of the site. (Only "Aside" was prepared as an example.)',
			),
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const btnOnClickLogout = () => {
		const objPostData = {};
		setIsLoading(true);
		
		dispatch(logout(objPostData))
		  .then(unwrapResult)
		  .then((originalPromiseResult) => {
			// setIsLoading(false);
			console.log("originalPromiseResult in auto logout");
			console.log(originalPromiseResult);
			const response = originalPromiseResult;
			if(response.data.error === false){
			  showNotification("You have been successfully logged out.",'','success')
			  localStorage.removeItem("token");
			  let redirectUrl = "/login";
			  navigate(redirectUrl);
			} else {
			  console.log("error in auto logout");
			  console.log("error in auto logout");
			  showNotification(response.data.msg,'','danger')
			  // showMsg("error", "Ops something went wrong, Please try again");
			}
		  })
		  .catch((rejectedValueOrSerializedError) => {
			setIsLoading(false);
			// showMsg("error", "Please correct the errors.");
			console.log("rejectedValueOrSerializedError auto logout");
			console.log("rejectedValueOrSerializedError auto logout");
			console.log("rejectedValueOrSerializedError auto logout");
			console.log(rejectedValueOrSerializedError);
			localStorage.removeItem("token");
			let redirectUrl = "/login";
			navigate(redirectUrl);
	
		  });
	};
	return (
		<Header>
			<HeaderLeft>
				<>
    			</>
				{/* <Popovers
					title='DashboardHeader.tsx'
					desc={<code>src/pages/_layout/_headers/DashboardHeader.tsx</code>}>
					HeaderLeft
				</Popovers>
				<code>DashboardHeader.tsx</code> */}
			</HeaderLeft>

			<HeaderRight>
				<div className='row g-3 align-items-center'>
					{/* <div className='col-auto'>
						<Popovers
							title='DashboardHeader.tsx'
							desc={<code>src/pages/_layout/_headers/DashboardHeader.tsx</code>}>
							HeaderRight
						</Popovers>
						<code className='ps-3'>John</code>
					</div> */}
					{/* Dark Mode */}
					{/* <div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers>
					</div> */}
					{/* Quick Panel */}
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								{/* eslint-disable-next-line react/jsx-props-no-spreading */}
								<Button {...styledBtn} icon='Person' aria-label='Quick menu' />
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
								<div className='row g-0'>
									{
										objUser.isAuth ?
										<Fragment>
											<div
												className={classNames(
													'col-12',
													'p-4',
													'd-flex justify-content-center',
													'fw-bold fs-5',
													'text-info',
													'border-bottom border-info',
													{
														'bg-l25-info': !darkModeStatus,
														'bg-lo25-info': darkModeStatus,
													},
												)}>
												Hello, {objUser.full_name}
											</div>
										</Fragment>
										: null
									}
									{/* <div
										className={classNames(
											'col-6 p-4 transition-base cursor-pointer bg-light-hover',
											'border-end border-bottom',
											{ 'border-dark': darkModeStatus },
										)}>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Public' size='3x' color='info' />
											<span>Dealers</span>
											<small className='text-muted'>Options</small>
										</div>
									</div>
									<div
										className={classNames(
											'col-6 p-4 transition-base cursor-pointer bg-light-hover',
											'border-bottom',
											{ 'border-dark': darkModeStatus },
										)}>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Upcoming' size='3x' color='success' />
											<span>Inbox</span>
											<small className='text-muted'>Configuration</small>
										</div>
									</div> */}
									<div
										className={classNames(
											'col-6 p-4 transition-base cursor-pointer bg-light-hover',
											'border-end',
											{ 'border-dark': darkModeStatus },
										)}
										onClick={() =>{
											navigate("/account-settings");
										}}
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='AccountBox' size='3x' color='warning' />
											<span>Account</span>
											<small className='text-muted'>Settings</small>
										</div>
									</div>
									<div className='col-6 p-4 transition-base cursor-pointer bg-light-hover'
										onClick={() => {
											btnOnClickLogout();
										}}
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Logout' size='3x' color='danger' />
											<span>Logout</span>
											<small className='text-muted'>User</small>
										</div>
									</div>
								</div>
							</DropdownMenu>
						</Dropdown>
					</div>
					{/* Lang Selector */}
					{/* <div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								{typeof getLangWithKey(i18n.language as ILang['key']['lng'])
									?.icon === 'undefined' ? (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										className='btn-only-icon'
										aria-label='Change language'
										data-tour='lang-selector'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>
								) : (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={
											getLangWithKey(i18n.language as ILang['key']['lng'])
												?.icon
										}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)}
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								{Object.keys(LANG).map((i) => (
									<DropdownItem key={LANG[i].lng}>
										<Button
											icon={LANG[i].icon}
											onClick={() => changeLanguage(LANG[i].lng)}>
											{LANG[i].text}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div> */}
				</div>
			</HeaderRight>
		</Header>
	);
};

export default DashboardHeader;
