import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
// import { demoPagesMenu } from '../../../../menu';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import showNotification from '../../../../components/extras/showNotification';

import queryString from 'query-string';
import AuthContext from '../../../../contexts/authContext';
import SmartSheetOAuthCallBackStatus from './components/SmartSheetOAuthCallBackStatus';
import LoadingSpninner from '../../../../utils/loading/LoadingSpninner';
import CommonAlert from '../../../../common/alerts/CommonAltert';
import { updateAuthUserData } from '../../../../features/user/userActions';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../features/storeConfig/store';
import { smartSheetOAuthCallback } from '../../../../features/smartSheet/smartSheetOAuth/smartSheetOAuthActions';
import { unwrapResult } from '@reduxjs/toolkit';

const SmartSheetOAuthCallBack = () => {

	const { objUser, setObjUser } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const urlParams = queryString.parse(window.location.search);
	const navigate = useNavigate();

	// const dispatch = useDispatch<any>();
	const dispatch = useAppDispatch();
	console.log('urlParams in SmartSheetOAuthCallBack')
	console.log('urlParams in SmartSheetOAuthCallBack')
	console.log(urlParams)
	console.log('objUser in SmartSheetOAuthCallBack');
	console.log(objUser);
	useEffect(() => {
		if (objUser !== undefined && Object.keys(objUser).length > 0 && Object.keys(urlParams).length > 0) {
			if (urlParams.code !== undefined) {

				const objPostData = {
					code:urlParams.code,
				};
				setIsLoading(true);
				dispatch(smartSheetOAuthCallback(objPostData))
					.then(unwrapResult)
					.then((originalPromiseResult: any) => {
						setIsLoading(false);
						console.log("originalPromiseResult in userLogin");
						console.log("originalPromiseResult in userLogin");
						console.log("originalPromiseResult in userLogin");
						console.log(originalPromiseResult);
						const response = originalPromiseResult;
						console.log("response.data in userLogin");
						console.log("response.data in userLogin");
						console.log("response.data in userLogin");
						console.log(response.data);
						if (response.data.error === false) {
							showNotification(response.data.msg, '', 'success')
							dispatch(updateAuthUserData(response.data.objUser))
							setObjUser({ isAuth: true, ...response.data.objUser })
							let redirectUrl = '/';
							navigate(redirectUrl);
						} else {
							showNotification(response.data.msg, '', 'danger');
							let redirectUrl = '/';
							navigate(redirectUrl);
						}
					})
					.catch((rejectedValueOrSerializedError: any) => {
						setIsLoading(false);
						showNotification("Ops something went wrong please try again", '', 'danger')
						console.log("rejectedValueOrSerializedError");
						console.log(rejectedValueOrSerializedError);
					});
			}else{
				showNotification("Invalid Url", '', 'danger')
				let redirectUrl = '/';
				// alert('else')
				navigate(redirectUrl);
			}
		}else{
			let isError = false
			if(Object.keys(urlParams).length === 0){
				isError = true;
			}
			if(Object.keys(urlParams).length > 0 && urlParams.code === undefined){
				isError = true;
			}

			if(isError){
				showNotification("Invalid Url", '', 'danger')
				let redirectUrl = '/';
				// alert('else isError')
				navigate(redirectUrl);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objUser]);




	return (
		<PageWrapper
			title={'Smartsheet login verification'}
			isProtected={false}
			page_auth_type={'dashboard_page'}
			checkAuth={true}
			isRedirect={true}
		>
			<Page>
				<div className='row h-100 align-content-start'>
					{
						objUser !== undefined && Object.keys(objUser).length > 0 && Object.keys(urlParams).length > 0 ?
							<SmartSheetOAuthCallBackStatus 
								isLoading={isLoading}
							/>
						: <LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' />
					}
				</div>

			</Page>
		</PageWrapper>
	);
};

export default SmartSheetOAuthCallBack;
