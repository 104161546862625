import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../utils/constants/misc";
import { USER_SERVER, USER_SETTINGS_SERVER } from "../../utils/constants/misc";

export const userLogin = createAsyncThunk("user/login", async (values:any, thunkApi) => {
  try {
    // configure header's Content-Type as JSON
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${USER_SERVER}/login`, values);
    // return res.data;
    // localStorage.setItem('userToken', data.userToken)
    return res;

    // store user's token in local storage
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});


export const auth = createAsyncThunk("user/auth", async (values:any, thunkApi) => {
  try {
    // console.log("values in auth");
    // console.log("values in auth");
    // console.log("values in auth");
    // console.log(values);
    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // };
    const res : AxiosResponse = await axios.get(`${BACKEND_API_URL}${USER_SERVER}/auth`);
    // console.log("res.data in auth");
    // console.log("res.data in auth");
    // console.log("res.data in auth");
    // console.log(res.data);
    // return res.data;
    return res.data;
    // return Promise.resolve(res.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
    // if (error.response && error.response.data.message) {
    //   return thunkApi.rejectWithValue(error.response.data.message);
    // } else {
    //   return thunkApi.rejectWithValue(error.message);
    // }
  }
});

export const logout = createAsyncThunk("user/logout", async (values:any, thunkApi) => {
  try {
    // console.log("values in auth");
    // console.log("values in auth");
    // console.log("values in auth");
    // console.log(values);
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${USER_SERVER}/logout`, values);
    // return res.data;
    return res.data;
    // return Promise.resolve(res.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
    // if (error.response && error.response.data.message) {
    //   return thunkApi.rejectWithValue(error.response.data.message);
    // } else {
    //   return thunkApi.rejectWithValue(error.message);
    // }
  }
});

export const authUpdateUser = createAsyncThunk("user/authUpdateUser", async (values:any, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${USER_SERVER}/user`, values);
    // return res.data;
    return res;
    // return Promise.resolve(res.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const updateAuthUserData = createAsyncThunk(
  "user/updateAuthUserData",
  async (values:any, thunkApi) => {
    try {
      // return res.data;
      const objUserData = {
        isAuth: true,
        _id: values._id,
        email: values.email,
        full_name:values.full_name,
        username:values.username,
        profile_pic:values.profile_pic,
        createdAt:values.createdAt, 
        smartsheet_token_data:values.smartsheet_token_data,
      };
      return objUserData;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);



export const savePersonalSettings = createAsyncThunk("user/savePersonalSettings", async (values:any, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${USER_SETTINGS_SERVER}/save-personal-settings`, values);
    // return res.data;
    return res;
    // return Promise.resolve(res.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});