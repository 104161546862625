import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	objUser: any;
	setObjUser: any;
	// setObjUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [objUser, setObjUser] = useState<any>({});
	const [userData, setObjUserData] = useState<Partial<IUserProps>>({});

	// useEffect(() => {
	// 	localStorage.setItem('facit_authUsername', user);
	// }, [user]);

	// useEffect(() => {
	// 	if (user !== '') {
	// 		setObjUserData(getUserDataWithUsername(user));
	// 	} else {
	// 		setObjUserData({});
	// 	}
	// }, [user]);

	const value = useMemo(
		() => ({
			objUser,
			setObjUser,
			userData,
		}),
		[objUser, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
