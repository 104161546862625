import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
// @ts-ignore

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import useDarkMode from '../../../../../hooks/useDarkMode';
// import YouTube from 'react-youtube';

const opts = {
    // height: '390',
    // width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
};
const DashboardYoutubeDemoVideo = () => {

	

	
	return (
		<>
			<Card stretch>
				<CardHeader>
					<CardLabel icon='Style' iconColor='info'>
						<CardTitle tag='div' className='h5'>
							App Demo
						</CardTitle>
					</CardLabel>
					{/* <CardActions>
						<Button
							color='info'
							icon='CreditCard'
							isLight
							// onClick={() => setModalStatus(true)}
						>
							Connect To Smartsheet
						</Button>
					</CardActions> */}
				</CardHeader>
				<CardBody>
					<div className='row g-3'>
						<div className='col-12'>
							{/* <YouTube videoId="ga6dQke7tb0" opts={opts} className={"youtubeContainer"}  />
							 */}
							 <Vimeo
								video="830708510"
								className={"youtubeContainer"}
								responsive={true}
							/>
						</div>
						
					</div>
				</CardBody>
			</Card>
			
		</>
	);
};

export default DashboardYoutubeDemoVideo;
