import React, { FC, Fragment } from "react"
import classNames from "classnames";
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import AutomationsHistoryAutomationListTr from "./AutomationsHistoryAutomationListTr";
interface IAutomationsHistoryObjAutomationListItemBlockProps {
    // objSelectedSourceWorkSpace?: any;
    // objSelectedSourceFolder?: any;
    // objSelectedSourceSheet?: any;
    // objSelectedSourceColumn?: any;
    // objSelectedDestinationWorkSpace?: any;
    // objSelectedDestinationFolder?: any;
    // objSelectedDestinationSheet?: any;
    handleOnClickSelectCheckBox: any;
    darkModeStatus: boolean;
    objAutomationsList: any;
    _id?: any;
    createdAt?: any;
}
const AutomationsHistoryObjAutomationListItemBlock: FC<IAutomationsHistoryObjAutomationListItemBlockProps> = (props) => {
    const {
        _id,
        createdAt,
        darkModeStatus,
        objAutomationsList
    } = props;
    
    // console.log('objAutomationsList in AutomationsHistoryObjAutomationListItemBlock');
    // console.log('objAutomationsList in AutomationsHistoryObjAutomationListItemBlock');
    // console.log('objAutomationsList in AutomationsHistoryObjAutomationListItemBlock');
    // console.log(objAutomationsList)
    // console.log('_id in AutomationsHistoryObjAutomationListItemBlock');
    // console.log('_id in AutomationsHistoryObjAutomationListItemBlock');
    // console.log('_id in AutomationsHistoryObjAutomationListItemBlock');
    // console.log(_id)
    
    return (
        objAutomationsList ?
            objAutomationsList.map((objAutomationListItem:any) => (
                <AutomationsHistoryAutomationListTr
                    key={objAutomationListItem.id}
                    darkModeStatus={darkModeStatus}
                    automation_design_id={_id}
                    createdAt={createdAt}
                    handleOnClickSelectCheckBox={props.handleOnClickSelectCheckBox}
                    {...objAutomationListItem}
                />
            ))
            : null
        
    )
}
export default AutomationsHistoryObjAutomationListItemBlock
