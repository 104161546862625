import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../utils/constants/misc";
import { DESIGN_AUTOMATIONS_SERVER } from "../../utils/constants/misc";


export const saveDesignAutomations = createAsyncThunk("designAutomations/saveDesignAutomations", async (values:any, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${DESIGN_AUTOMATIONS_SERVER}/save-design-automation`, values);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchDesignAutomationsHistory = createAsyncThunk("designAutomations/fetchDesignAutomationsHistory", async (values:any, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${DESIGN_AUTOMATIONS_SERVER}/fetch-design-automations-history`, values);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const clearDesignAutomationHistory = createAsyncThunk("designAutomations/clearDesignAutomationHistory", async (_:void, thunkApi) => {
  try {
    const objClearAutomationPayLoad = {
      objDesignAutomations: [],
      total_design_automations:0,
      error: false
    };
    return objClearAutomationPayLoad;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const toggleCheckBoxDesignAutomationsHistory = createAsyncThunk("designAutomations/toggleCheckBoxDesignAutomationsHistory", async (objToggleData:any, thunkApi) => {
  try {
    return objToggleData;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const checkAllCheckBoxDesignAutomationsHistory = createAsyncThunk("designAutomations/checkAllCheckBoxDesignAutomationsHistory", async (_:void, thunkApi) => {
  try {
    return true;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});




