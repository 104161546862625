import React, { FC, Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
// import { demoPagesMenu } from '../../../../../menu';
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
    SubheaderSeparator,
} from '../../../../../layout/SubHeader/SubHeader';
import Page from '../../../../../layout/Page/Page';
import showNotification from '../../../../../components/extras/showNotification';
import Icon from '../../../../../components/icon/Icon';
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../../../components/bootstrap/Card';
import Button from '../../../../../components/bootstrap/Button';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Spinner from '../../../../../components/bootstrap/Spinner';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import Breadcrumb from '../../../../../components/bootstrap/Breadcrumb';
import Avatar from '../../../../../components/Avatar';
import USERS from '../../../../../common/data/userDummyData';
import CommonDesc from '../../../../../common/other/CommonDesc';
import Label from '../../../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../../../components/bootstrap/forms/Checks';
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from '../../../../../features/storeConfig/store';
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';

interface IAccountSettingsFormikFormWrapperProps {
    passwordChangeCTA: boolean,
    setPasswordChangeCTA: any,
    isLoading: boolean,
    setIsLoading: any,
    values: any;
    errors: any;
    touched: any;
    setFieldValue: any;
}

const AccountSettingsFormFormikWrapper: FC<IAccountSettingsFormikFormWrapperProps> = (props) => {
    const { passwordChangeCTA, setPasswordChangeCTA, isLoading, setIsLoading,
        values, errors, touched, setFieldValue
    } = props;
    useEffect(() => {
        setFieldValue('is_change_password_enabled',passwordChangeCTA)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[passwordChangeCTA]);
    return (
        <Fragment >
            
            <div className='col-md-12'>

                <Card>
                    <CardHeader>
                        <CardLabel icon='Person' iconColor='success'>
                            <CardTitle tag='div' className='h5'>
                                Personal Information
                            </CardTitle>
                            <CardSubTitle tag='div' className='h6'>
                                User's credentials
                            </CardSubTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4'>
                            <div className='col-md-6'>
                                <FormGroup className={`${errors.name && touched.name && "text-danger"}`}>
                                    <Label >Name</Label>
                                    <Field
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        className={`form-control ${errors.name &&
                                            touched.name &&
                                            "is-invalid"}`}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">{errors.name}</div>
                                    ) : <></>}
                                </FormGroup>
                            </div>
                            <div className='col-md-6'>
                                <FormGroup className={`${errors.email && touched.email && "text-danger"}`}>
                                    <Label >Email</Label>
                                    <Field
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        disabled={true}
                                        className={`form-control ${errors.email &&
                                            touched.email &&
                                            "is-invalid"}`}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="invalid-feedback">{errors.email}</div>
                                    ) : <></>}
                                </FormGroup>
                            </div>

                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <CardLabel icon='LocalPolice' iconColor='primary'>
                            <CardTitle tag='div' className='h5'>
                                Password
                            </CardTitle>
                            <CardSubTitle tag='div' className='h6'>
                                Password change operations
                            </CardSubTitle>
                        </CardLabel>
                        <CardActions>
                            {passwordChangeCTA ? (
                                <Button
                                    color='danger'
                                    isLight
                                    icon='Cancel'
                                    onClick={() => setPasswordChangeCTA(false)}>
                                    Cancel
                                </Button>
                            ) : (
                                <>
                                    <span>Do you want to change?</span>
                                    <Button
                                        color='primary'
                                        isLight
                                        icon='PublishedWithChanges'
                                        onClick={() => setPasswordChangeCTA(true)}>
                                        Yes
                                    </Button>
                                </>
                            )}
                        </CardActions>
                    </CardHeader>
                    {passwordChangeCTA && (
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-12'>
                                    <FormGroup className={`${errors.password && touched.password && "text-danger"}`}>
                                        <Label >Password</Label>
                                        <Field
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="Password"
                                            className={`form-control ${errors.password &&
                                                touched.password &&
                                                "is-invalid"}`}
                                        />
                                        {errors.password && touched.password ? (
                                            <div className="invalid-feedback">{errors.password}</div>
                                        ) : <></>}
                                    </FormGroup>
                                </div>

                            </div>{' '}
                        </CardBody>
                    )}
                    <CardFooter>
                        <CommonDesc>
                            Pro tip: We recommend changing your password every 90 days.
                        </CommonDesc>
                    </CardFooter>
                </Card>
            </div>

            <div className='col-12'>
                <Card>
                    <CardBody>
                        <div className='row align-items-center'>
                            <div className='col'>

                            </div>
                            <div className='col-auto'>
                                <div className='row g-1'>
                                    <div className='col-auto'>
                                    {
                                        isLoading === false ?
                                        <Button
                                            className='me-3'
                                            icon={isLoading ? undefined : 'Save'}
                                            isLight
                                            color={'success'}
                                            isDisable={isLoading}
                                            type='submit'
                                        >
                                            Save
                                        </Button>
                                        : 
                                        <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                                         
                                     }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Fragment>
    );
};

export default AccountSettingsFormFormikWrapper;
