import React, { 
	useLayoutEffect, forwardRef, ReactElement, useContext, useEffect, 
	useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import AuthContext from '../../contexts/authContext';
import { demoPagesMenu } from '../../menu';
import { useDispatch, useSelector } from "react-redux";
import { auth } from '../../features/user/userActions';
import { useAppDispatch } from '../../features/storeConfig/store';
interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
		| ReactElement<ISubHeaderProps>[]
		| ReactElement<IPageProps>
		| ReactElement<IPageProps>[];
	className?: string;
	page_auth_type?: string;
	checkAuth?: boolean;
	isRedirect?: boolean;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children, page_auth_type,checkAuth, isRedirect }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
				process.env.REACT_APP_SITE_NAME
			}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});
		// const dispatch = useDispatch();
		// const dispatch = useDispatch<any>();
		const dispatch = useAppDispatch();
		const { objUser, setObjUser } = useContext(AuthContext);
		const _isMounted = useRef(true); // Initial value _isMounted = true
		const [loading, setLoading] = useState(false);
		const navigate = useNavigate();
		useEffect(() => {
			// if (isProtected && user === '') {
			// 	navigate(`../${demoPagesMenu.login.path}`);
			// }
			loadAuthData()
			return () => {};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [title]);

		const loadAuthData = async () => {
			// alert("loadAuthData");
			setLoading(true);
			const objUserAuthResp = await dispatch(auth({}));
			// console.log("objUserAuthResp in RedirectToDashboardIfLoggedInCheck");
			// console.log("objUserAuthResp in RedirectToDashboardIfLoggedInCheck");
			// console.log(objUserAuthResp);
			// console.log("objUserAuthResp.payload in RedirectToDashboardIfLoggedInCheck");
			// console.log("objUserAuthResp.payload in RedirectToDashboardIfLoggedInCheck");
			// console.log(objUserAuthResp.payload);
			// console.log("page_auth_type in RedirectToDashboardIfLoggedInCheck");
			// console.log("page_auth_type in RedirectToDashboardIfLoggedInCheck");
			// console.log(page_auth_type);

			const objUserAuth = objUserAuthResp.payload;
			setObjUser(objUserAuth)
			if(page_auth_type === 'login_pages'){
				if (checkAuth && !objUserAuth.isAuth) {
					// if (isRedirect) {
					// 	navigate('/login');
					// 	return false;
					// }
				} else if (checkAuth && objUserAuth.isAuth) {
					navigate("/");
					return false;
				}
			}else if(page_auth_type === 'dashboard_page'){
				if (checkAuth && !objUserAuth.isAuth) {
					if (isRedirect) {
						navigate('/login');
						return false;
					}
				} else if (checkAuth && objUserAuth.isAuth) {
					// navigate("/");
					return false;
				}
			}
			setLoading(false);
		};
		// const { objUser } = useSelector((state) => ({
		// 	objUser: state.auth.user.objUser,
		// 	// objUser: state.user.objUser,
		// }));
		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
