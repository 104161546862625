import React, { FC, Fragment } from "react"
import classNames from "classnames";
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import ScheduledAutomationsListTr from "./ScheduledAutomationsListTr";
interface IScheduledAutomationsListItemBlockProps {
    // objSelectedSourceWorkSpace?: any;
    // objSelectedSourceFolder?: any;
    // objSelectedSourceSheet?: any;
    // objSelectedSourceColumn?: any;
    // objSelectedDestinationWorkSpace?: any;
    // objSelectedDestinationFolder?: any;
    // objSelectedDestinationSheet?: any;
    handleOnClickDeleteScheduledAutomation: any;
    darkModeStatus: boolean;
    list: any;
    _id?: any;
    createdAt?: any;
}
const ScheduledAutomationsListItemBlock: FC<IScheduledAutomationsListItemBlockProps> = (props) => {
    const {
        _id,
        createdAt,
        darkModeStatus,
        list
    } = props;
    
    // console.log('list in ScheduledAutomationsListItemBlock');
    // console.log('list in ScheduledAutomationsListItemBlock');
    // console.log('list in ScheduledAutomationsListItemBlock');
    // console.log(list)
    const renderScheduledAutomationsList = () => (
        list ?
            list.map((objScheduledAutomationListItem:any) => (
                <ScheduledAutomationsListTr
                    key={objScheduledAutomationListItem._id}
                    darkModeStatus={darkModeStatus}
                    createdAt={createdAt}
                    handleOnClickDeleteScheduledAutomation={props.handleOnClickDeleteScheduledAutomation}
                    {...objScheduledAutomationListItem}
                />
            ))
            : <></>
            
    )

    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <tr className="text-center">
                        <td className="text-center" colSpan={6}> No records found.</td>
                    </tr>
                    : null
                : null}
            {renderScheduledAutomationsList()}
        </Fragment>
    )

}
export default ScheduledAutomationsListItemBlock
