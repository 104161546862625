import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Alert from '../../components/bootstrap/Alert';
import { TColor } from '../../type/color-type';

interface ICommonAlertProps {
	children: ReactNode;
	className?: string;
	color?: TColor;
    icon?: any,
}
const CommonAlert: FC<ICommonAlertProps> = ({ children, className, color, icon }) => {
	return (
		<Alert
			color={color}
			isLight
			shadow='md'
			borderWidth={0}
			icon={icon}
			className={classNames('flex-nowrap', 'w-100', 'mb-0', className)}>
			{children}
		</Alert>
	);
};
CommonAlert.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
	]),
};
CommonAlert.defaultProps = {
	className: undefined,
	color: 'warning',
    icon: 'info'
};

export default CommonAlert;
