import { combineReducers } from "redux";
import userReducers from "../../features/user";
import workSpacesReducers from "../../features/smartSheet/workSpaces";
import workSpaceFoldersReducers from "../../features/smartSheet/workSpaceFolders";
import smartSheetOAuthReducers from "../../features/smartSheet/smartSheetOAuth";
import designAutomationsReducers from "../../features/designAutomations";
import scheduleAutomationsReducers from "../../features/scheduleAutomations";


const rootReducer = combineReducers({
  auth: userReducers,
  workSpaces: workSpacesReducers,
  workSpaceFolders: workSpaceFoldersReducers,
  smartSheetOAuth: smartSheetOAuthReducers,
  designAutomations:designAutomationsReducers,
  scheduleAutomations:scheduleAutomationsReducers,
});

export default rootReducer;
