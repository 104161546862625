import { createSlice } from "@reduxjs/toolkit";
import {
  fetchWorkSpaceFoldersByWorkSpaceId,
  fetchWorkSpaceFolderSheetsByFolderId,
  fetchWorkSpaceFolderSheetColumnsBySheetId,
} from "./workSpaceFoldersActions";
// import type { RootState } from '../../features/storeConfig/store'
import type { PayloadAction } from '@reduxjs/toolkit'


// import { getUserDetails, registerUser,  } from "./userActions";

// initialize userToken from local storage
// const userToken = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;
type InitialStateType = {
  objWorkSpaceFoldersDataByWorkSpaceId?: any;
  objWorkSpaceFolderSheetsDataByFolderId?: any;
  objWorkSpaceFolderSheetColumnsDataBySheetId?: any;
  loading?: boolean;
}
const initialState : InitialStateType = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
  
};

const workSpaceSlice : any = createSlice({
  name: "workSpace",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: (builder) => {
    builder
    // fetchWorkSpaceFoldersByWorkSpaceId
    .addCase(fetchWorkSpaceFoldersByWorkSpaceId.pending, (state) => {
        state.loading = true;
    })
    .addCase(fetchWorkSpaceFoldersByWorkSpaceId.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objWorkSpaceFoldersDataByWorkSpaceId = action.payload;
    })
    .addCase(fetchWorkSpaceFoldersByWorkSpaceId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objWorkSpaceFoldersDataByWorkSpaceId = action.payload;
    })
    
    // fetchWorkSpaceFolderSheetsByFolderId
    .addCase(fetchWorkSpaceFolderSheetsByFolderId.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchWorkSpaceFolderSheetsByFolderId.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objWorkSpaceFolderSheetsDataByFolderId = action.payload;
    })
    .addCase(fetchWorkSpaceFolderSheetsByFolderId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objWorkSpaceFolderSheetsDataByFolderId = action.payload;
    })

    // fetchWorkSpaceFolderSheetColumnsBySheetId
    .addCase(fetchWorkSpaceFolderSheetColumnsBySheetId.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchWorkSpaceFolderSheetColumnsBySheetId.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objWorkSpaceFolderSheetColumnsDataBySheetId = action.payload;
    })
    .addCase(fetchWorkSpaceFolderSheetColumnsBySheetId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objWorkSpaceFolderSheetColumnsDataBySheetId = action.payload;
    })
    

    
  },
});

export default workSpaceSlice.reducer;
