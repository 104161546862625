import React, { lazy } from 'react';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login/Login';
import DASHBOARD from '../pages/presentation/dashboard/DashboardPage';
import DESIGN_AUTOMATION from '../pages/app/DesignAutomation/DesignAutomation';
import AUTOMATION_HISTORY from '../pages/app/AutomationHistory/AutomationHistory';
import AUTOMATION_MANAGER from '../pages/app/AutomationManager/AutomationManager';
import ACCOUNT_SETTINGS from '../pages/app/user/AccountSettings/AccountSettings';

// const LANDING = {
// 	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
// 	DESIGN_AUTOMATION: lazy(() => import('../pages/app/DesignAutomation/DesignAutomation')),
// 	AUTOMATION_HISTORY: lazy(() => import('../pages/app/AutomationHistory/AutomationHistory')),
// 	AUTOMATION_MANAGER: lazy(() => import('../pages/app/AutomationManager/AutomationManager')),
// 	ACCOUNT_SETTINGS: lazy(() => import('../pages/app/user/AccountSettings/AccountSettings')),
// };
// const AUTH = {
// 	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
// };
// const PAGE_LAYOUTS = {
// 	// HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
// 	// HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
// 	// SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
// 	// CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
// 	// BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
// 	// ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
// 	// MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
// };

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <DASHBOARD />,
	},
	{
		path: dashboardPagesMenu.designAutomation.path,
		element: <DESIGN_AUTOMATION />,
	},
	{
		path: dashboardPagesMenu.automationHistory.path,
		element: <AUTOMATION_HISTORY />,
	},
	{
		path: dashboardPagesMenu.automationManager.path,
		element: <AUTOMATION_MANAGER />,
	},
	{
		path: dashboardPagesMenu.accountSettings.path,
		element: <ACCOUNT_SETTINGS />,
	},
	// {
	// 	path: demoPagesMenu.page404.path,
	// 	element: <AUTH.PAGE_404 />,
	// },
	// {
	// 	path: demoPagesMenu.login.path,
	// 		element: <Login />,
	// 	},
	// {
	// 	path: demoPagesMenu.signUp.path,
	// 	element: <Login isSignUp />,
	// },

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	// {
	// 	path: pageLayoutTypesPagesMenu.blank.path,
	// 	element: <PAGE_LAYOUTS.BLANK />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PAGE_LAYOUTS.HEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
	// 	element: <PAGE_LAYOUTS.SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
	// 	element: <PAGE_LAYOUTS.CONTENT />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
	// 	element: <PAGE_LAYOUTS.ASIDE />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
	// 	element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	// },
];
const contents = [...presentation];

export default contents;
