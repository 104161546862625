import { createSlice, current } from "@reduxjs/toolkit";
import {
  saveScheduleAutomations,
  fetchScheduledAutomations,
  clearScheduledAutomations,
  deleteScheduleAutomationById,
  deleteScheduleAutomationFromTable,
} from "./scheduleAutomationsActions";
// import type { RootState } from '../../features/storeConfig/store'
import type { PayloadAction } from '@reduxjs/toolkit'

type InitialStateType = {
  objSaveScheduleAutomationsFormSuccess?: any;
  objDeleteScheduleAutomationFormSuccess?: any;
  objScheduleAutomations?: any;
  loading?: boolean;
}
const initialState: InitialStateType = {
  objScheduleAutomations: {
    objScheduleAutomations: [],
    total_schedule_automations: 0,
    error: false
  }
};

const scheduleAutomationsSlice: any = createSlice({
  name: "scheduleAutomations",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      // objSaveScheduleAutomationsFormSuccess
      .addCase(saveScheduleAutomations.pending, (state) => {
        state.loading = true;
        state.objSaveScheduleAutomationsFormSuccess = null;
      })
      .addCase(saveScheduleAutomations.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSaveScheduleAutomationsFormSuccess = action.payload;
      })
      .addCase(saveScheduleAutomations.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objSaveScheduleAutomationsFormSuccess = action.payload;
      })

      // objDeleteScheduleAutomationFormSuccess
      .addCase(deleteScheduleAutomationById.pending, (state) => {
        state.loading = true;
        state.objDeleteScheduleAutomationFormSuccess = null;
      })
      .addCase(deleteScheduleAutomationById.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objDeleteScheduleAutomationFormSuccess = action.payload;
      })
      .addCase(deleteScheduleAutomationById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objDeleteScheduleAutomationFormSuccess = action.payload;
      })
      // deleteScheduleAutomationFromTable
      .addCase(deleteScheduleAutomationFromTable.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        console.log('action.payload in deleteScheduleAutomationFromTable.fulfilled');
        console.log('action.payload in deleteScheduleAutomationFromTable.fulfilled');
        console.log(action.payload);
        console.log("current(state)");
        console.log("current(state)");
        console.log("current(state)");
        console.log(current(state));
        console.log("current(state.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations)");
        console.log(current(state.objScheduleAutomations));
        console.log("current(state.objScheduleAutomations.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations.objScheduleAutomations)");
        console.log(current(state.objScheduleAutomations.objScheduleAutomations));
        
        const objNewobjScheduleAutomationsData = state.objScheduleAutomations.objScheduleAutomations.filter(
          (item:any) => item._id !== action.payload._id
        );
        
        state.objScheduleAutomations.objScheduleAutomations = objNewobjScheduleAutomationsData;
        state.objScheduleAutomations.total_schedule_automations = state.objScheduleAutomations.total_schedule_automations - 1;
      })
      .addCase(deleteScheduleAutomationFromTable.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objScheduleAutomations = action.payload;
      })
      

      // objScheduleAutomations
      // .addCase(fetchScheduledAutomations.pending, (state) => {
      //   state.loading = true;
      //   state.objScheduleAutomations = {};
      //   // state.objScheduleAutomations = null;
      // })
      .addCase(fetchScheduledAutomations.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        console.log('action.payload in fetchScheduledAutomations.fulfilled');
        console.log('action.payload in fetchScheduledAutomations.fulfilled');
        console.log(action.payload);
        // console.log('state in fetchScheduledAutomations.fulfilled');
        // console.log('state in fetchScheduledAutomations.fulfilled');
        // console.log(state);
        console.log("current(state)");
        console.log("current(state)");
        console.log("current(state)");
        console.log(current(state));
        console.log("current(state.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations)");
        console.log(current(state.objScheduleAutomations));
        console.log("current(state.objScheduleAutomations.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations.objScheduleAutomations)");
        console.log("current(state.objScheduleAutomations.objScheduleAutomations)");
        console.log(current(state.objScheduleAutomations.objScheduleAutomations));
        state.objScheduleAutomations.objScheduleAutomations = [...state.objScheduleAutomations.objScheduleAutomations, ...action.payload.objScheduleAutomations]
        state.objScheduleAutomations.error = action.payload.error;
        state.objScheduleAutomations.msg = action.payload.msg;
        state.objScheduleAutomations.total_schedule_automations = action.payload.total_schedule_automations;
        
      })
      .addCase(fetchScheduledAutomations.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objScheduleAutomations = action.payload;
      })

      // clearScheduledAutomations
      .addCase(clearScheduledAutomations.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.objScheduleAutomations = action.payload;
      })

      

  },
});

export default scheduleAutomationsSlice.reducer;
