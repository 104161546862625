import React, { FC, useCallback, useContext, useState, Fragment } from 'react';

import CommonAlert from '../../../../../common/alerts/CommonAltert';
import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';

interface ISmartSheetOAuthCallBackStatusProps {
    isLoading: boolean,
}
const SmartSheetOAuthCallBackStatus: FC<ISmartSheetOAuthCallBackStatusProps> = (props) => {
    const { isLoading } = props;
    return (
        <Fragment>
            {
                isLoading ?
                    <Fragment>
                        <CommonAlert color='info' icon='Cancel'>
                            We are verifying your Smartsheet account,Please wait.
                        </CommonAlert>
                        <LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' />
                    </Fragment>
                    :
                    <LoadingSpninner tag='div' color='primary' size={30} subClass='text-center' />
            }

        </Fragment>

    );
};


export default SmartSheetOAuthCallBackStatus;
