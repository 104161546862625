import { combineReducers } from "redux";
// import authReducers from "./auth/";
import designAutomationsSlice from "./designAutomationsSlice";
// import userSignUpFunnelSlice from "./userSignUpFunnelSlice";
// stockPrices/
const designAutomationsReducers = combineReducers({
  designAutomations: designAutomationsSlice,
  // userSignUpFunnelSlice,
});

export default designAutomationsReducers;
