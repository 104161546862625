import React, { FC, Fragment } from "react"
import classNames from "classnames";
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { formatDate } from "../../../../helpers/Date/DateTimeHelpers";
interface IAutomationsHistoryAutomationListTrProps {
    objSelectedSourceWorkSpace?: any;
    objSelectedSourceFolder?: any;
    objSelectedSourceSheet?: any;
    objSelectedSourceColumn?: any;
    objSelectedDestinationWorkSpace?: any;
    objSelectedDestinationFolder?: any;
    objSelectedDestinationSheet?: any;
    objSelectedDestinationColumn?: any;
    automation_design_id?: any;
    createdAt?: any;
    is_checked: boolean;
    id: any;
    handleOnClickSelectCheckBox: any;
}
const AutomationsHistoryAutomationListTr: FC<IAutomationsHistoryAutomationListTrProps> = (props) => {
    
    const {objSelectedSourceWorkSpace,
        objSelectedSourceFolder,
        objSelectedSourceSheet,
        objSelectedSourceColumn,
        objSelectedDestinationWorkSpace,
        objSelectedDestinationFolder,
        objSelectedDestinationSheet,
        objSelectedDestinationColumn,createdAt,automation_design_id
    }= props;
    // console.log('props')
    // console.log('props')
    // console.log('props')
    // console.log(props)
    // // console.log('props.is_checked')
    // // console.log('props.is_checked')
    // // console.log('props.is_checked')
    // // console.log(props.is_checked)
    // console.log('props.id')
    // console.log('props.id')
    // console.log('props.id')
    // console.log(props.id)
    
    return (
        <Fragment>
            <tr >
                <td className="text-center">
                    <FormGroup check>
                        <Input type="checkbox" 
                            // onChange={props.handleOnClickSelectCheckBox} 
                            onChange={event => props.handleOnClickSelectCheckBox(event,props)}
                            checked={props.is_checked}
                            value={props.id}
                        />
                        <Label check>
                            {/* {props.id} */}
                        </Label>
                    </FormGroup>
                </td>
                <td>Success</td>
                <td>

                    {objSelectedDestinationWorkSpace.label}
                </td>
                <td>
                    {
                        objSelectedDestinationFolder !== undefined ?
                            objSelectedDestinationFolder.label
                        : null
                    }
                </td>
                <td>
                    {objSelectedDestinationSheet.label}
                </td>
                <td>
                    {objSelectedDestinationColumn.label}
                </td>

                {/* Source */}
                <td>

                    {objSelectedSourceWorkSpace.label}
                </td>
                <td>
                    {
                        objSelectedSourceFolder !== undefined ?
                            objSelectedSourceFolder.label
                        : null
                    }
                </td>
                <td>
                    {objSelectedSourceSheet.label}
                </td>
                <td>
                    {objSelectedSourceColumn.label}
                </td>
                <td>
                    {formatDate(createdAt)}
                </td>

                    
            </tr>
        </Fragment>
        
    )
}
export default AutomationsHistoryAutomationListTr
