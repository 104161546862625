import React, { FC, Fragment, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { userLogin } from '../../../../../features/user/userActions';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from '../../../../../features/storeConfig/store';
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardFooterLeft,
    CardFooterRight,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../../../components/bootstrap/Card';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import Select from "react-select"
import makeAnimated from "react-select/animated";
import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';
import DestinationDynamicTreeSection from './DestinationDynamicTreeSection';
const animatedComponents = makeAnimated();
interface IDestinationAutomationSectionProps {
    formattedWorkSpacesArr: any;
    setFormattedWorkSpacesArr: any;
    objSelectedDestinationWorkSpace: any;
    setObjSelectedDestinationWorkSpace: any;
    formattedDestinationFoldersArr: any;
    setFormattedDestinationFoldersArr: any;
    objSelectedDestinationFolder: any;
    setObjSelectedDestinationFolder: any;
    formattedDestinationSheetsArr: any;
    setFormattedDestinationSheetsArr: any;
    objSelectedDestinationSheet: any;
    setObjSelectedDestinationSheet: any;
    formattedDestinationColumnsArr: any;
    setFormattedDestinationColumnsArr: any;
    objSelectedDestinationColumn: any;
    setObjSelectedDestinationColumn: any;
    fetchWorkSpaceDataByWorkSpaceIdHandler: any;
    fetchWorkSpaceFoldersByWorkSpaceIdHandler: any;
    fetchWorkSpaceFolderSheetsByFolderIdHandler: any;
    fetchWorkSpaceSheetColumnsBySheetIdHandler: any;
    isLoadingAllWorkSpaces: boolean;
    isLoadingDestinationWorkSpaceFoldersByWorkSpaceId: boolean;
    isLoadingDestinationWorkSpaceFolderSheetsByFolderId: boolean;
    isLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId: boolean;
    isLoadingDestinationWorkSpaceByWorkSpaceId: boolean;
    objFormErrors: any;
}
const DestinationAutomationSection: FC<IDestinationAutomationSectionProps> = (props) => {


    const { formattedWorkSpacesArr,setFormattedWorkSpacesArr,objSelectedDestinationWorkSpace,
        setObjSelectedDestinationWorkSpace,formattedDestinationFoldersArr,setFormattedDestinationFoldersArr,
        objSelectedDestinationFolder,setObjSelectedDestinationFolder,formattedDestinationSheetsArr,
        setFormattedDestinationSheetsArr,objSelectedDestinationSheet,setObjSelectedDestinationSheet,
        formattedDestinationColumnsArr,setFormattedDestinationColumnsArr,objSelectedDestinationColumn,
        setObjSelectedDestinationColumn,fetchWorkSpaceFoldersByWorkSpaceIdHandler,
        fetchWorkSpaceDataByWorkSpaceIdHandler,
        fetchWorkSpaceFolderSheetsByFolderIdHandler,
        fetchWorkSpaceSheetColumnsBySheetIdHandler,
        isLoadingAllWorkSpaces,isLoadingDestinationWorkSpaceFoldersByWorkSpaceId,isLoadingDestinationWorkSpaceFolderSheetsByFolderId,isLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId,
        isLoadingDestinationWorkSpaceByWorkSpaceId,
        objFormErrors
    } = props;

    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnChangeDestinationWorkSpace = (selectedOptions: any) => {
        console.log('selectedOptions in handleOnChangeDestinationWorkSpace');
        console.log('selectedOptions in handleOnChangeDestinationWorkSpace');
        console.log(selectedOptions);
        setObjSelectedDestinationWorkSpace(selectedOptions);
        setFormattedDestinationFoldersArr([]);
        setFormattedDestinationSheetsArr([]);
        setFormattedDestinationColumnsArr([]);
        setObjSelectedDestinationFolder({});
        setObjSelectedDestinationSheet({});
        setObjSelectedDestinationColumn({});
        fetchWorkSpaceFoldersByWorkSpaceIdHandler(selectedOptions.id,'destination');
        // fetchWorkSpaceDataByWorkSpaceIdHandler(selectedOptions.id,'destination');
    };

    const handleOnChangeDestinationFolder = (selectedOptions: any) => {
        console.log('selectedOptions in handleOnChangeDestinationFolder');
        console.log('selectedOptions in handleOnChangeDestinationFolder');
        console.log(selectedOptions);
        setObjSelectedDestinationFolder(selectedOptions);
        setFormattedDestinationSheetsArr([]);
        setFormattedDestinationColumnsArr([]);
        setObjSelectedDestinationSheet({});
        setObjSelectedDestinationColumn({});
        fetchWorkSpaceFolderSheetsByFolderIdHandler(selectedOptions.id,'destination')
    };

    const handleOnChangeDestinationSheet = (selectedOptions: any) => {
        console.log('selectedOptions in handleOnChangeDestinationSheet');
        console.log('selectedOptions in handleOnChangeDestinationSheet');
        console.log(selectedOptions);
        setObjSelectedDestinationSheet(selectedOptions);
        setFormattedDestinationColumnsArr([]);
        setObjSelectedDestinationColumn({});
        fetchWorkSpaceSheetColumnsBySheetIdHandler(selectedOptions.id,'destination');
    };

    const handleOnChangeDestinationColumn = (selectedOptions: any) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedDestinationColumn(selectedOptions);
        
    };
    return (
        <Col md="6">
            <Card>
                <CardHeader>
                    <CardLabel icon='DriveFileMove' iconColor='success'>
                        <CardTitle tag='div' className='h5'>
                            Destination
                        </CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody>
                    {
                        isLoadingAllWorkSpaces === false ?
                        <Fragment>
                            <DestinationDynamicTreeSection 
                                formattedWorkSpacesArr={formattedWorkSpacesArr}
                                setFormattedWorkSpacesArr={setFormattedWorkSpacesArr}
                                fetchWorkSpaceFoldersByWorkSpaceIdHandler={fetchWorkSpaceFoldersByWorkSpaceIdHandler}
                                fetchWorkSpaceFolderSheetsByFolderIdHandler={fetchWorkSpaceFolderSheetsByFolderIdHandler}
                                setObjSelectedDestinationWorkSpace={setObjSelectedDestinationWorkSpace}
                                setObjSelectedDestinationFolder={setObjSelectedDestinationFolder}
                                setObjSelectedDestinationSheet={setObjSelectedDestinationSheet}
                                setObjSelectedDestinationColumn={setObjSelectedDestinationColumn}
                                handleOnChangeDestinationSheet={handleOnChangeDestinationSheet}
                            />
                            {/* <Row className='g-4'>
                                <Col md="6">
                                    <FormGroup className={`${objFormErrors.objSelectedDestinationWorkSpace  && "text-danger"}`}>
                                        <h6 className="text-bold-100">Workspace</h6>
                                        <Select
                                            components={animatedComponents}
                                            name="drpDestinationWorkSpace"
                                            options={formattedWorkSpacesArr}
                                            className="React"
                                            classNamePrefix="select"
                                            value={objSelectedDestinationWorkSpace}
                                            onChange={handleOnChangeDestinationWorkSpace}
                                        />
                                        {objFormErrors.objSelectedDestinationWorkSpace ? (
                                            <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedDestinationWorkSpace}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    {
                                        isLoadingDestinationWorkSpaceFoldersByWorkSpaceId === false && isLoadingDestinationWorkSpaceByWorkSpaceId === false ?
                                        <FormGroup className={``}>
                                            <h6 className="text-bold-100">Folder</h6>
                                            <Select
                                                components={animatedComponents}
                                                name="drpDestinationFolder"
                                                options={formattedDestinationFoldersArr}
                                                className="React"
                                                classNamePrefix="select"
                                                value={objSelectedDestinationFolder}
                                                onChange={handleOnChangeDestinationFolder}
                                            />
                                            
                                        </FormGroup>
                                        : <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                                    }
                                </Col>
                            </Row> */}
                            <Row className='g-4'>
                                
                                {/* <Col md="6">
                                    {
                                        isLoadingDestinationWorkSpaceFolderSheetsByFolderId === false && isLoadingDestinationWorkSpaceByWorkSpaceId === false ?
                                        <FormGroup className={`${objFormErrors.objSelectedDestinationSheet  && "text-danger"}`}>
                                            <h6 className="text-bold-100">Sheets</h6>
                                            <Select
                                                components={animatedComponents}
                                                name="drpDestinationSheet"
                                                options={formattedDestinationSheetsArr}
                                                className="React"
                                                classNamePrefix="select"
                                                value={objSelectedDestinationSheet}
                                                onChange={handleOnChangeDestinationSheet}
                                            />
                                            {objFormErrors.objSelectedDestinationSheet ? (
                                                    <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedDestinationSheet}</div>
                                                ) : null}
                                        </FormGroup>
                                        : <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />

                                    }
                                </Col> */}
                                <Col md="12">
                                        {
                                            isLoadingDestinationWorkSpaceFolderSheetColumnsBySheetId === false ?
                                            <FormGroup className={`${objFormErrors.objSelectedDestinationColumn  && "text-danger"}`}>
                                                <h6 className="text-bold-100">Column</h6>
                                                <Select
                                                    components={animatedComponents}
                                                    name="drpDestinationColumn"
                                                    options={formattedDestinationColumnsArr}
                                                    className="React"
                                                    classNamePrefix="select"
                                                    value={objSelectedDestinationColumn}
                                                    onChange={handleOnChangeDestinationColumn}
                                                />
                                                {objFormErrors.objSelectedDestinationColumn ? (
                                                    <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedDestinationColumn}</div>
                                                ) : null}
                                            </FormGroup>
                                            : <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                                        }
                                </Col>
                                
                            </Row>
                        </Fragment>
                        : 
                        <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                    }

                </CardBody>
                
            </Card>
        </Col>

    );
};


export default DestinationAutomationSection;
