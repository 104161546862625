export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
// export const BASE_URL =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_API_URL_PRODUCTION
//     : process.env.REACT_APP_API_URL;
// export const APP_URL =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_APP_URL_PRODUCTION
//     : process.env.REACT_APP_APP_URL;
export const USER_SERVER = "/api/users";
export const USER_SETTINGS_SERVER = "/api/users-settings";
export const COMPANIES_SERVER = "/api/companies";
export const CLIENTS_SERVER = "/api/clients";
export const SMART_SHEET_OAUTH_SERVER = "/api/smartsheet-oauth";
export const SMART_SHEET_SERVER = "/api/smartsheets";
export const DESIGN_AUTOMATIONS_SERVER = "/api/design-automations";
export const SCHEDULE_AUTOMATIONS_SERVER = "/api/schedule-automations";

