import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../utils/constants/misc";
import { SCHEDULE_AUTOMATIONS_SERVER } from "../../utils/constants/misc";


export const saveScheduleAutomations = createAsyncThunk("scheduleAutomations/saveScheduleAutomations", async (values:any, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${SCHEDULE_AUTOMATIONS_SERVER}/save-schedule-automation`, values);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const deleteScheduleAutomationById = createAsyncThunk("scheduleAutomations/deleteScheduleAutomationById", async (values:any, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${SCHEDULE_AUTOMATIONS_SERVER}/delete-schedule-automation`, values);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchScheduledAutomations = createAsyncThunk("scheduleAutomations/fetchScheduledAutomations", async (values:any, thunkApi) => {
  try {
    const res : AxiosResponse = await axios.post(`${BACKEND_API_URL}${SCHEDULE_AUTOMATIONS_SERVER}/fetch-schedule-automations`, values);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const clearScheduledAutomations = createAsyncThunk("scheduleAutomations/clearScheduledAutomations", async (_:void, thunkApi) => {
  try {
    const objClearScheduleAutomationPayLoad = {
      objScheduleAutomations: [],
      total_schedule_automations:0,
      error: false
    };
    return objClearScheduleAutomationPayLoad;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const deleteScheduleAutomationFromTable = createAsyncThunk("scheduleAutomations/deleteScheduleAutomationFromTable", async (values:any, thunkApi) => {
  try {
    return values;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
