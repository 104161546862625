import React, { FC, Fragment } from "react";
import AutomationsHistoryListItemBlock from './AutomationsHistoryListItemBlock'
interface ILoadAutomationsHistoryListProps {
    objDesignAutomations: any;
    handleOnClickSelectCheckBox: any;
    darkModeStatus: boolean;
}
const LoadAutomationsHistoryList: FC<ILoadAutomationsHistoryListProps> = (props) => {
    const { objDesignAutomations
    } = props;
    return (
        <Fragment>
            
            <AutomationsHistoryListItemBlock
                list={props.objDesignAutomations.objDesignAutomations.objDesignAutomations}
                darkModeStatus={props.darkModeStatus}
                handleOnClickSelectCheckBox={props.handleOnClickSelectCheckBox}
            />
        </Fragment>
    )
}
export default LoadAutomationsHistoryList
