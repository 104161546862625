import React, { FC, Fragment } from "react";
import AutomationsListItemBlock from './AutomationsListItemBlock'
interface ILoadAutomationsListProps {
    objAutomationsList: any;
    btnOnClickRemoveAutomation: any;
    darkModeStatus: boolean;
}
const LoadAutomationsList: FC<ILoadAutomationsListProps> = (props) => {
    const { objAutomationsList
    } = props;
    return (
        <Fragment>
            <AutomationsListItemBlock
                list={props.objAutomationsList}
                darkModeStatus={props.darkModeStatus}
                btnOnClickRemoveAutomation={props.btnOnClickRemoveAutomation}
            />
        </Fragment>
    )
}
export default LoadAutomationsList
