import React, { FC, Fragment, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Label, Form, FormGroup, Input } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import classNames from 'classnames';
import { useAppDispatch } from '../../../../../features/storeConfig/store';
import Button, { ButtonGroup } from '../../../../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardFooterLeft,
    CardFooterRight,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../../../components/bootstrap/Card';
import LoadingSpninner from '../../../../../utils/loading/LoadingSpninner';
import LoadAutomationsList from './components/LoadAutomationsList';
interface IAutomationsListSectionProps {
    objAutomationsList: any;
    btnOnClickRemoveAutomation: any;
    btnOnClickRunAutomation: any;
    darkModeStatus: boolean;
    isRunningAutomations: boolean;
}
const AutomationsListSection: FC<IAutomationsListSectionProps> = (props) => {
    const { objAutomationsList, darkModeStatus, btnOnClickRemoveAutomation,
        btnOnClickRunAutomation, isRunningAutomations
    } = props;

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    return (
        <Col lg={12}>
            <Card stretch={true}>
                <CardHeader borderSize={1}>
                    <CardLabel icon='FormatListBulleted' iconColor='info'>
                        <CardTitle tag='div' className='h5'>
                            Your Automation List
                        </CardTitle>
                    </CardLabel>
                    <CardActions>
                        {
                            isRunningAutomations === false ?
                            <Button
                                color='success'
                                icon='Send'
                                isLight
                                onClick={() => btnOnClickRunAutomation()}
                            >
                                Run Automation
                            </Button>
                            : 
                            <LoadingSpninner  tag='div' color='primary' size={30} subClass='text-center' />
                        }
                    </CardActions>
                </CardHeader>
                <CardBody className='table-responsive' >
                    <table className='table table-modern'>
                        <thead>
                            <tr>
                                <th>Workspace Name</th>
                                <th>Folder Name</th>
                                <th>Sheet Name</th>
                                <th>Column Name</th>
                                <td />
                            </tr>
                        </thead>
                        {
                            objAutomationsList !== undefined ?
                            <tbody>
                                <LoadAutomationsList
                                    objAutomationsList={objAutomationsList}
                                    darkModeStatus={darkModeStatus}
                                    btnOnClickRemoveAutomation={btnOnClickRemoveAutomation}
                                />
                            </tbody>
                            : null
                        }
                        <tbody>
                            
                        </tbody>
                    </table>
                </CardBody>

            </Card>




        </Col>

    );
};


export default AutomationsListSection;
