import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
// import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
// import Input from '../../../../components/bootstrap/forms/Input';
import Button from '../../../../components/bootstrap/Button';
import Logo from '../../../../components/Logo';
import useDarkMode from '../../../../hooks/useDarkMode';
import AuthContext from '../../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../../common/data/userDummyData';
import Spinner from '../../../../components/bootstrap/Spinner';
import Alert from '../../../../components/bootstrap/Alert';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import loginBgVideo from '../../../../assets/video/auth/login-bg-video.mp4';
import "../../../../styles/pages/auth/LoginPage.scss";
import LogoPng from  "../../../../assets/img/logo.png";
import LoginForm from './components/LoginForm';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	
	return (
		<>
			<div className='text-center h1 fw-bold'>Welcome,</div>
			<div className='text-center h4 text-muted mb-50'>Sign in to continue!</div>
			{/* <div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div> */}
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	// const { setObjUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();


	// const navigate = useNavigate();

	

	

	// const [isLoading, setIsLoading] = useState<boolean>(false);
	

	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			page_auth_type={'login_pages'}
			checkAuth={true}
			isRedirect={true}
			// page_auth_type={'dashboard_page'}
			className={'bg-dark loginWrapper LoginPage vid-container'}>
			<Page className='p-0'>
				{/* <video className="bgvid" autoPlay muted preload="auto" loop>
					<source src={loginBgVideo} type="video/mp4" />
				</video> */}
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card data-tour='login-page'>
						{/* <Card className='shadow-3d-dark' data-tour='login-page'> */}
							<CardBody>
								{/* <div className='text-center my-5'> */}
								<div className='text-center'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Curated Automation'>
										{/* <Logo width={200} /> */}
										<img src={LogoPng} width={300} alt='Curated Automation' />
									</Link>
								</div>
								{/* <div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div> */}

								<LoginHeader isNewUser={false} />

								
								<LoginForm />
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3 link-dark', {
								})}
								>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none link-dark', {
								})}
								>
								Terms of use
							</a>
						</div> */}
						
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
